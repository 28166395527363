import Skeleton from 'react-loading-skeleton';

import Breadcrumb from '../shared/breadcrumb/Breadcrumb';
import CardProduct from '../shared/cards/CardProduct';
import NewPagination from '../shared/pagination/NewPagination';

const initBreadcrumb = [
  {
    // link: '/categories',
    name: 'Categories',
  },
];

export default function Main({data}) {

  return (
    <>
      <div className='min-h-[300px] mb-8'>
        <div className='bg-slate-100'>
          <div className='min-h-[60px] py-5 flex justify-between items-center container'>
            <Breadcrumb breadcrumbs={initBreadcrumb} />
            <div></div>
          </div>
        </div>
        <div className='grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 sm:gap-8 gap-4 container my-6'>
          {data
            ? data.data?.map((item) => (
                <CardProduct key={item._id} item={item} button={false} path='categories' />
              ))
            : [...Array(8)].map((_, index) => (
                <Skeleton
                  key={index}
                  containerClassName='max-w-sm mx-auto border border-gray-200 rounded-lg shadow w-full block h-[300px]'
                  className='!block w-full h-full'
                />
              ))}
        </div>

        <div className='mx-auto w-full text-center py-8 container'>
          <NewPagination total={data?.total || 0} />
        </div>
      </div>
    </>
  );
}
