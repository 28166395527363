import { Link, useLocation } from 'react-router-dom';

export default function Filter({ data, title, keyword }) {
  const location = useLocation();

  const scrollTop = () => window.scrollTo(0, 0);

  return (
    <>
      <div className='md:block hidden sticky top-[120px] self-start'>
        <h2 className='uppercase font-bold text-gray-800 text-lg'>{title}</h2>
        <div className='w-8 h-1 bg-slate-300 rounded-lg my-2'></div>
        <ul>
          {data &&
            data.map((item) => (
              <li key={item._id}>
                {!keyword ? (
                  <Link
                    to={`/${title}/${item.path}/page/1`}
                    onClick={scrollTop}
                    className={
                      'py-2 inline-block font-medium hover:font-bold outline-none ' +
                      (location.pathname.indexOf(item.path) >= 0
                        ? '!font-bold text-emerald-500'
                        : ' hover:text-gray-800')
                    }
                  >
                    {item.name}
                  </Link>
                ) : (
                  <Link
                    to={`/${title}/${item.path}/page/1`}
                    onClick={scrollTop}
                    className={
                      'py-2 inline-block font-medium hover:font-bold outline-none ' +
                      (keyword === item._id
                        ? '!font-bold text-emerald-500'
                        : ' hover:text-gray-800')
                    }
                  >
                    {item.name}
                  </Link>
                )}
              </li>
            ))}
        </ul>
      </div>
    </>
  );
}
