import { sloganProduct } from '../../../assets/json/other';


export default function SloganProduct() {
  return (
    <>
      <div className='w-full text-center py-6'>
        <span className='text-emerald-500 italic '>{ sloganProduct.text }</span>
      </div>
    </>
  )
}

