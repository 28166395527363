export default function BadgeSession({ id, listBadges, startIndex = 0 }) {
  return (
    <>
      {listBadges && listBadges?.length > 0 && (
        <>
          <span
            id={id}
            className='h-[160px] -mt-[160px] md:h-[320px] md:-mt-[320px] invisible block'
          ></span>
          <div className='grid sm:grid-cols-3 grid-cols-1 gap-12 md:w-3/4 mx-auto my-8'>
            {[...Array(3)].map((_, index) => (
              <div key={index} className='text-center'>
                <img
                  className='object-cover w-[80px] h-[80px] mx-auto'
                  src={require('../../../assets/images/home/' +
                    listBadges[index + startIndex].nameFile)}
                  alt=''
                />
                <h3
                  className='uppercase text-base py-4 text-bg-transparent font-bold'
                  style={{
                    background: listBadges[index + startIndex].color || 'black',
                  }}
                >
                  {listBadges[index + startIndex].title}
                </h3>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
}
