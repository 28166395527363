// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import Skeleton from 'react-loading-skeleton';
import { useEffect, useState, useRef } from 'react';
import { getByMaterial } from '../../services/product';
import CardProduct from '../shared/cards/CardProduct';
import OrderModal from '../shared/modal/OrderModal';
// import required modules
import { FreeMode, Navigation, Pagination } from 'swiper';

// Import Swiper styles
// import 'swiper/css';

import './styles/product.css';
import 'swiper/css/free-mode';

const pagination = {
  clickable: true,
  bulletClass: 'swiper-pagination-bullet ',
  renderBullet: (index, className) => {
    return `<span class="${className} inline-block !w-[20px] !h-[20px] border-[7px] border-transparent rounded-full !ml-0 !mr-[10px] transition duration-500 !bg-transparent">
      <span class='h-full w-full block rounded-full bg-gray-600'></span>
    </span>`;
  },
};

export default function RelatedProduct({ data }) {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const [products, setProducts] = useState();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();

  useEffect(() => {
    if (data) {
      getByMaterial({
        page: 1,
        pageSize: 12,
        path: data.material.path,
      }).then((res) => setProducts(res.data.filter((p) => p._id !== data._id)));
    }
  }, [data]);

  return (
    <>
      <OrderModal
        open={isOpenModal}
        setClose={setIsOpenModal}
        selectedProduct={selectedProduct}
      />
      <h2 className='text-3xl font-semibold text-emerald-500 mb-6'>
        Related products
      </h2>
      <Swiper
        className='!grid'
        pagination={pagination}
        freeMode={true}
        modules={[Pagination, FreeMode, Navigation]}
        // slidesPerGroup={2}
        slidesPerView={2}
        spaceBetween={16}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        onBeforeInit={(swiper) => {
          swiper.params.navigation.prevEl = navigationPrevRef.current;
          swiper.params.navigation.nextEl = navigationNextRef.current;
        }}
        breakpoints={{
          // when window width is >= 320px
          // 320: {
          //   slidesPerView: 2,
          //   slidesPerGroup: 2,
          //   spaceBetween: 20,
          // },
          // when window width is >= 480px
          480: {
            slidesPerView: 2,
            // slidesPerGroup: 2,
            spaceBetween: 16,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 3,
            // slidesPerGroup: 3,
            spaceBetween: 32,
          },
          // when window width is >= 768px
          768: {
            slidesPerView: 4,
            // slidesPerGroup: 4,
            spaceBetween: 32,
          },
          // when window width is >= 1024px
          1024: {
            slidesPerView: 5,
            // slidesPerGroup: 5,
            spaceBetween: 32,
          },
        }}
      >
        {products
          ? products.map((item) => (
              <SwiperSlide key={item._id}>
                <CardProduct
                  item={item}
                  button={true}
                  setSelectedProduct={setSelectedProduct}
                  setIsOpenModal={setIsOpenModal}
                />
              </SwiperSlide>
            ))
          : [...Array(8)].map((_, index) => (
              <SwiperSlide key={index}>
                <Skeleton className='min-h-[200px] lg:' />
              </SwiperSlide>
            ))}

        <button
          className='flickity-button flickity-prev-next-button previous z-2'
          ref={navigationPrevRef}
        >
          <svg className='flickity-button-icon' viewBox='0 0 100 100'>
            <path
              d='M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z'
              className='arrow'
            ></path>
          </svg>
        </button>
        <button
          className='flickity-button flickity-prev-next-button next z-2'
          ref={navigationNextRef}
        >
          <svg className='flickity-button-icon' viewBox='0 0 100 100'>
            <path
              d='M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z'
              className='arrow'
              transform='translate(100, 100) rotate(180) '
            ></path>
          </svg>
        </button>
      </Swiper>
    </>
  );
}
