import { useEffect, useState } from 'react';
import { getVideos } from '../services/video';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { seo } from '../assets/json/seo';

export const ProductVideo = () => {
  const [data, setData] = useState();
  const [pageToken, setPageToken] = useState('');
  const [pageSize, setPageSize] = useState(8);

  useEffect(() => {
    getVideos({
      pageSize,
      pageToken,
    }).then((res) => setData(res));

    return () => {
      setData([]);
    };
  }, [pageToken, pageSize]);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{seo.productVideos.title}</title>
          <meta name='keywords' content={seo.productVideos.keywords} />
          <meta name='description' content={seo.productVideos.description} />
        </Helmet>
      </HelmetProvider>
      <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 container my-6'>
        {data?.items?.map((item) => (
          <div
            key={item.id.videoId}
            className='max-w-sm mx-auto bg-white border border-gray-200 shadow w-full flex flex-col h-full'
            title={item.snippet.title}
          >
            <iframe
              src={`https://www.youtube.com/embed/${item.id.videoId}`}
              title={item.snippet.title}
              width='100%'
              height='250px'
              allowFullScreen
            />
          </div>
        ))}
      </div>
      <div className='flex justify-center items-center gap-2 mb-4'>
        <button
          disabled={!data?.prevPageToken}
          onClick={() => setPageToken(data?.prevPageToken)}
          className='bg-emerald-500 text-white px-4 py-2 rounded-md disabled:bg-gray-400'
        >
          Prev
        </button>
        <button
          disabled={!data?.nextPageToken}
          onClick={() => setPageToken(data?.nextPageToken)}
          className='bg-emerald-500 text-white px-4 py-2 rounded-md disabled:bg-gray-400'
        >
          Next
        </button>
        <select
          value={pageSize}
          onChange={(e) => setPageSize(e.target.value)}
          className='bg-white text-black px-4 py-2 rounded-md border'
        >
          <option value='8'>8</option>
          <option value='16'>16</option>
          <option value='24'>24</option>
          <option value='100'>100</option>
          <option value='1000'>1000</option>
        </select>
      </div>
    </>
  );
};
