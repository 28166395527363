export default function FilterButton({ setIsOpenDrawer }) {
  return (
    <>
      <button
        className='inline-flex justify-center items-center w-12 h-12 bg-white border border-gray-400 rounded-full  gap-2 font-semibold hover:bg-gray-200 fixed right-6 bottom-1 z-3 text-sm md:hidden'
        onClick={() => setIsOpenDrawer(true)}
      >
        Menu
        {/* <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth={1.5}
          stroke='currentColor'
          className='w-6 h-6'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75'
          />
        </svg> */}
      </button>
    </>
  );
}
