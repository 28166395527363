import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';

export default function InfoDetail({ data, setIsOpenModal }) {
  return (
    <>
      <div className='md:w-5/12 md:pl-6 mt-4 pt-4 border-t w-full md:border-none md:pt-0 md:mt-0 md:pr-6'>
        {data ? (
          <>
            <h1 className='text-3xl font-semibold pb-4 mb-2 text-emerald-500'>
              {data.name}
            </h1>
            {/* <div className='mb-2'>
              <h2 className='text-2xl font-semibold text-emerald-500'>
                Product Specification:
              </h2>
              <p
                className='min-h-[20px] my-4 text-lg'
                dangerouslySetInnerHTML={{ __html: data.description }}
              ></p>
            </div> */}
            <div className='mb-2'>
              <h2 className='text-2xl font-semibold text-emerald-500'>
                Details:
              </h2>
              <p
                className='min-h-[20px] my-4 text-lg'
                dangerouslySetInnerHTML={{ __html: data.detail }}
              ></p>
            </div>
            <div className='flex'>
              <div className='flex items-end'>
                <button
                  className='sm:inline-flex hidden items-center px-4 py-3 text-sm font-medium text-center text-white bg-rose-500 rounded-full hover:bg-rose-600 focus:ring-4 focus:outline-none focus:ring-rose-300'
                  onClick={() => setIsOpenModal(true)}
                >
                  Request Quotation
                  <svg
                    aria-hidden='true'
                    className='w-4 h-4 ml-2 -mr-1'
                    fill='currentColor'
                    viewBox='0 0 20 20'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      fillRule='evenodd'
                      d='M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z'
                      clipRule='evenodd'
                    ></path>
                  </svg>
                </button>
                <Link
                  to={`/order/${data.path}`}
                  className='inline-flex sm:hidden items-center px-4 py-3 text-sm font-medium text-center text-white bg-rose-500 rounded-full hover:bg-rose-600 focus:ring-4 focus:outline-none focus:ring-rose-300'
                >
                  Request Quotation
                  <svg
                    aria-hidden='true'
                    className='w-4 h-4 ml-2 -mr-1'
                    fill='currentColor'
                    viewBox='0 0 20 20'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      fillRule='evenodd'
                      d='M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z'
                      clipRule='evenodd'
                    ></path>
                  </svg>
                </Link>
              </div>
            </div>
          </>
        ) : (
            <>
              <Skeleton height={30} />
              <div className='my-2'>
                <Skeleton className='mb-2' width={300} height={25} />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </div>
              <Skeleton width={100} height={35} />
            </>
        )}
      </div>
    </>
  );
}
