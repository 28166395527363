import { useState } from 'react';
import {info} from '../../../assets/json/info';
import SearchForm from './SearchForm';

export default function HeaderSearch() {
  const [showSearchForm, setShowSearchForm] = useState(false);

  return (
    <>
      <SearchForm
        showSearchForm={showSearchForm}
        setShowSearchForm={setShowSearchForm}
      />
      <div className='max-h-full'>
        <ul className='flex justify-end items-center'>
          <li className='hidden lg:block text-[.8rem] text-gray-500 font-bold hover:text-gray-800 mx-3 last:mr-0'>
            <a
              href={'tel:' + info.phone}
              className={
                'uppercase leading-[43px] py-2 tracking-wide transition-all flex items-center gap-1'
              }
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth={1.5}
                stroke='currentColor'
                className='w-5 h-5'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z'
                />
              </svg>
              {info.phone}
            </a>
          </li>
          <li className='hidden lg:block text-[.8rem] text-gray-500 font-bold hover:text-gray-800 mx-3 last:mr-0'>
            <a
              href={'mailto:' + info.email[0]}
              className={
                'leading-[43px] py-2 tracking-wide transition-all flex items-center gap-1'
              }
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth={1.5}
                stroke='currentColor'
                className='w-5 h-5'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75'
                />
              </svg>{' '}
              {info.email[0]}
            </a>
          </li>
          <li
            className='text-[.8rem] text-gray-500 font-bold hover:text-gray-800 mx-3 last:mr-0 cursor-pointer'
            onClick={() => setShowSearchForm(true)}
          >
            <div className='uppercase leading-[43px] py-2 tracking-wide transition-all flex items-center'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth={1.5}
                stroke='currentColor'
                className='w-5 h-5'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z'
                />
              </svg>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
}
