import instance from './axios';

export const search = async (data) =>
  (
    await instance.post('/product/search', {
      ...data,
    })
  ).data;

export const searchFeaturedProducts = async (data) =>
  (
    await instance.post('/product/searchFeaturedProducts', {
      ...data,
    })
  ).data;

export const getById = async (id) =>
  (await instance.get('/product/getById/' + id)).data;

export const getByPath = async (id) =>
  (await instance.get('/product/getByPath/' + id)).data;

export const getByCategory = async (data) =>
  (
    await instance.post('/product/getByCategory', {
      ...data,
    })
  ).data;

export const getByMaterial = async (data) =>
  (
    await instance.post('/product/getByMaterial', {
      ...data,
    })
  ).data;
