import { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useLocation, useOutletContext, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { seo } from '../assets/json/seo';
import Main from '../components/product/MainProductDetail';
import { getByPath } from '../services/product';

const breadcrumb = [
  {
    link: '/products',
    name: 'Products',
  },
];

export default function ProductDetail() {
  const { categories } = useOutletContext();

  const { path } = useParams();

  const [product, setProduct] = useState();
  const [initBreadcrumb, setInitBreadcrumb] = useState(breadcrumb);

  useEffect(() => {
    if (path)
    {
      setTimeout(() => {
        getByPath(path)
          .then((res) => setProduct(res))
          .catch((err) =>
            toast.error('Error while loading product. Please try again!')
          );
      })
    }
  }, [path]);

  useEffect(() => {
    if (product?.name)
    {
      setInitBreadcrumb(breadcrumb);
      setInitBreadcrumb([...breadcrumb, { name: product.name }]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product?.name]);

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>
            {product?.name || 'Product'} {seo.product.title}
          </title>
          <meta name='keywords' content={seo.product.keywords} />
          <meta name='description' content={seo.product.description} />
        </Helmet>
      </HelmetProvider>
      <Main data={product} initBreadcrumb={initBreadcrumb} categories={categories} />
    </>
  );
}
