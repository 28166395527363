import React, { useState } from 'react';
import { faqType, faqsData } from '../../assets/json/faqs';

export default function Main() {
  const [expanded, setExpanded] = useState([]);

  console.log(expanded);

  return (
    <>
      <div className='my-8 container'>
        <h1 className='text-3xl text-gray-500 font-bold text-center pt-6 container uppercase mb-4'>
          FAQs
        </h1>
        <div className='relative font-inter antialiased'>
          <main className='relative min-h-screen flex flex-col items-center overflow-hidden'>
            {faqType.map((item, indexType) => (
              <React.Fragment key={item.id}>
                <div className='w-full max-w-2xl mx-auto px-4 md:px-6 py-4'>
                  <h1 className='text-2xl font-bold text-emerald-500'>
                    {item.label}
                  </h1>

                  {faqsData
                    .filter((i) => i.type === item.id)
                    .map((itemData, index) => (
                      <div
                        key={index}
                        className={`divide-y divide-slate-200 ${
                          faqsData.filter((i) => i.type === item.id).length -
                            1 >
                          index
                            ? 'border-b'
                            : ''
                        }`}
                      >
                        <div x-data='{ expanded: false }' className='py-2'>
                          <h2>
                            <button
                              id='faqs-title-01'
                              type='button'
                              className='flex items-center justify-between w-full text-left font-semibold py-2'
                              onClick={() => {
                                if (
                                  expanded.find((e) => e === item.label + index)
                                ) {
                                  const data = JSON.parse(
                                    JSON.stringify(expanded)
                                  );
                                  const i = data.findIndex(
                                    (p) => p === item.label + index
                                  );
                                  data.splice(i, 1);

                                  setExpanded(data);
                                } else
                                  setExpanded([
                                    ...expanded,
                                    item.label + index,
                                  ]);
                              }}
                              aria-controls='faqs-text-01'
                            >
                              <span>{itemData.question}</span>
                              <svg
                                className='fill-indigo-500 shrink-0 ml-8'
                                width='16'
                                height='16'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <rect
                                  y='7'
                                  width='16'
                                  height='2'
                                  rx='1'
                                  className={`transform origin-center transition duration-200 ease-out ${
                                    expanded.find(
                                      (e) => e === item.label + index
                                    )
                                      ? 'rotate-180'
                                      : ''
                                  }`}
                                />
                                <rect
                                  y='7'
                                  width='16'
                                  height='2'
                                  rx='1'
                                  className={`transform origin-center rotate-90 transition duration-200 ease-out ${
                                    expanded.find(
                                      (e) => e === item.label + index
                                    )
                                      ? 'rotate-0'
                                      : ''
                                  }`}
                                />
                              </svg>
                            </button>
                          </h2>
                          <div
                            id='faqs-text-01'
                            role='region'
                            aria-labelledby='faqs-title-01'
                            className={`grid text-sm text-slate-600 overflow-hidden transition-all duration-300 ease-in-out ${
                              expanded.find((e) => e === item.label + index)
                                ? 'grid-rows-[1fr] opacity-100'
                                : 'grid-rows-[0fr] opacity-0'
                            }`}
                          >
                            <div className='overflow-hidden'>
                              <p
                                className='pb-3'
                                dangerouslySetInnerHTML={{
                                  __html: itemData.answer,
                                }}
                              ></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
                {faqType.length - 1 > indexType && (
                  <div className='w-full max-w-2xl px-4 md:px-6 h-[2px]'>
                    <div className='w-full h-full  bg-slate-500'></div>
                  </div>
                )}
              </React.Fragment>
            ))}
          </main>
        </div>
      </div>
    </>
  );
}
