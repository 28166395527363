import { useEffect, useState } from 'react';

export default function TopButton() {
  const [showButton, setShowButton] = useState(false);

  const handleToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) setShowButton(true);
      else setShowButton(false);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (window.scrollY > 100) setShowButton(true);
    else setShowButton(false);
  }, []);

  return (
    <>
      <button
        className={
          'inline-flex justify-center items-center w-12 h-12 bg-white border border-gray-400 rounded-full gap-2 uppercase font-semibold hover:bg-gray-200 fixed right-6 bottom-14 z-3 transition ' +
          (showButton ? 'opacity-100' : 'opacity-0')
        }
        onClick={handleToTop}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth={1.5}
          stroke='currentColor'
          className='w-6 h-6'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75'
          />
        </svg>
      </button>
    </>
  );
}
