import Joi from 'joi';

export function contactValidator(data) {
  const rule = Joi.object({
    ...data,
    name: Joi.string().required(),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .allow(null, ''),
    phone: Joi.string()
      .label('Phone')
      .pattern(
        new RegExp('^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$')
      )
      .required(),
    content: Joi.string().required(),
  }).messages({
    'string.empty': `Cannot be empty.`,
    'date.base': `Malformed: dd/MM/yyyy.`,
    'number.base': `Malformed: 1 2 3 4 5`,
    'any.required': `Cannot be empty.`,
    'string.pattern.base': `Your {#label} is invalid`,
  });

  return rule.validate(data, { abortEarly: false });
}

export function emailValidator(data) {
  const rule = Joi.object({
    ...data,
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .allow(null, ''),
  }).messages({
    'string.pattern.base': `Your {#label} is invalid`,
  });

  return rule.validate(data, { abortEarly: false });
}

export function orderValidator(data) {
  const rule = Joi.object({
    ...data,
    name: Joi.string().required(),
    phone_number: Joi.string()
      .label('Phone')
      .pattern(
        new RegExp('^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$')
      )
      .required(),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required(),
    subject: Joi.string().required(),
    message: Joi.string().required(),
  }).messages({
    'string.empty': `Cannot be empty.`,
    'date.base': `Malformed: dd/MM/yyyy.`,
    'number.base': `Malformed: 1 2 3 4 5`,
    'any.required': `Cannot be empty.`,
    'string.pattern.base': `Your {#label} is invalid`,
  });

  return rule.validate(data, { abortEarly: false });
}
