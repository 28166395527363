import { Link } from 'react-router-dom';

export default function Filter({ categories, materials }) {

  return (
    <>
      <div className='md:w-2/12 md:block md:pr-4 hidden sticky top-[120px] self-start'>
        <h2 className='uppercase font-bold text-gray-800 text-lg'>Categories</h2>
        <div className='w-8 h-1 bg-slate-300 rounded-lg my-2'></div>
        <ul>
          {categories &&
            categories.map((item) => (
              <li key={item._id}>
                <Link
                  to={`/categories/${item.path}/page/1`}
                  className={
                    'py-2 inline-block hover:text-gray-800 font-medium hover:font-bold outline-none '
                  }
                >
                  {item.name}
                </Link>
              </li>
            ))}
        </ul>
              
        <h2 className='uppercase font-bold text-gray-800 text-lg mt-4'>Materials</h2>
        <div className='w-8 h-1 bg-slate-300 rounded-lg my-2'></div>
        <ul>
          {materials &&
            materials.map((item) => (
              <li key={item._id}>
                <Link
                  to={`/materials/${item.path}/page/1`}
                  className={
                    'py-2 inline-block hover:text-gray-800 font-medium hover:font-bold outline-none '
                  }
                >
                  {item.name}
                </Link>
              </li>
            ))}
        </ul>
      </div>
    </>
  );
}
