import { useRef } from 'react';
import Skeleton from 'react-loading-skeleton';
// import { Link } from 'react-router-dom';
import { Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import CardProduct from '../../shared/cards/CardProduct';

export default function MaterialSession({ materials }) {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  // const handleClick = (e) => {
  //   sessionStorage.setItem('scrollPosition', window.scrollY);
  // };

  return (
    <>
      <span
        id='materials'
        className='h-[75px] -mt-[75px] md:h-[165px] md:-mt-[165px] invisible block'
      ></span>
      <div className='container min-h-[200px] bg-gray-50 !py-8 rounded'>
        <div className='mx-auto'>
          {materials ? (
            <h2 className='uppercase text-4xl text-gray-500 font-bold tracking-wide text-center pb-4'>
              Materials
            </h2>
          ) : (
            <Skeleton containerClassName='pb-4 block' height={45} />
          )}
          {materials ? (
            <>
              <Swiper
                className='!hidden sm:!grid'
                // pagination={pagination}
                modules={[Autoplay, Navigation]}
                autoplay={{
                  delay: 3000,
                  pauseOnMouseEnter: true,
                }}
                navigation={{
                  prevEl: navigationPrevRef.current,
                  nextEl: navigationNextRef.current,
                }}
                onBeforeInit={(swiper) => {
                  swiper.params.navigation.prevEl = navigationPrevRef.current;
                  swiper.params.navigation.nextEl = navigationNextRef.current;
                }}
                slidesPerGroup={2}
                slidesPerView={2}
                spaceBetween={16}
                breakpoints={{
                  // when window width is >= 320px
                  // 320: {
                  //   slidesPerView: 2,
                  //   slidesPerGroup: 2,
                  //   spaceBetween: 20,
                  // },
                  // when window width is >= 480px
                  480: {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                    spaceBetween: 16,
                  },
                  // when window width is >= 640px
                  640: {
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                    spaceBetween: 32,
                  },
                  // when window width is >= 768px
                  768: {
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                    spaceBetween: 32,
                  },
                  // when window width is >= 1024px
                  1024: {
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                    spaceBetween: 32,
                  },
                }}
              >
                {materials?.map((item) => (
                  <SwiperSlide key={item._id}>
                    <CardProduct
                      key={item._id}
                      item={item}
                      button={false}
                      path='materials'
                      // setIsOpenModal={setIsOpenModal}
                      // setSelectedProduct={setSelectedProduct}
                    />
                    {/* <Link
                      key={item._id}
                      to={'/materials/' + item.path + '/page/1'}
                      onClick={handleClick}
                      className='block max-w-sm mx-auto text-center group lg:hover:p-4 lg:hover:bg-white lg:hover:shadow rounded transition-all duration-500'
                    >
                      <h3 className='py-2 block text-icon transition-all lg:group-hover:opacity-80 sm:text-base lg:text-xl text-base'>
                        {item.name}
                      </h3>
                      <div className='overflow-hidden lg:group-hover:rounded'>
                        <img
                          className='object-cover lg:group-hover:scale-110 lg:group-hover:rounded transition-all duration-500'
                          src={item.thumbnail}
                          alt=''
                        />
                      </div>
                    </Link> */}
                  </SwiperSlide>
                ))}
                <button
                  className='flickity-button flickity-prev-next-button previous z-2'
                  ref={navigationPrevRef}
                >
                  <svg className='flickity-button-icon' viewBox='0 0 100 100'>
                    <path
                      d='M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z'
                      className='arrow'
                    ></path>
                  </svg>
                </button>
                <button
                  className='flickity-button flickity-prev-next-button next z-2'
                  ref={navigationNextRef}
                >
                  <svg className='flickity-button-icon' viewBox='0 0 100 100'>
                    <path
                      d='M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z'
                      className='arrow'
                      transform='translate(100, 100) rotate(180) '
                    ></path>
                  </svg>
                </button>
              </Swiper>
              <div className='sm:hidden grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4'>
                {materials?.map((item) => (
                  // <Link
                  //   key={item._id}
                  //   to={'/materials/' + item.path + '/page/1'}
                  //   onClick={handleClick}
                  //   className='max-w-sm mx-auto text-center group lg:hover:p-4 lg:hover:bg-white lg:hover:shadow rounded transition-all duration-500'
                  // >
                  //   <h3 className='py-2 block text-icon transition-all lg:group-hover:opacity-80 sm:text-base lg:text-xl text-base'>
                  //     {item.name}
                  //   </h3>
                  //   <div className='overflow-hidden lg:group-hover:rounded sm:rounded-none sm:shadow-none shadow rounded'>
                  //     <img
                  //       className='object-cover lg:group-hover:scale-110 lg:group-hover:rounded transition-all duration-500'
                  //       src={item.thumbnail}
                  //       alt=''
                  //     />
                  //   </div>
                  // </Link>
                  <CardProduct
                    key={item._id}
                    item={item}
                    button={false}
                    path='materials'
                    // setIsOpenModal={setIsOpenModal}
                    // setSelectedProduct={setSelectedProduct}
                  />
                ))}
              </div>
            </>
          ) : (
            <div className='grid md:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-[32px]'>
              {[...Array(4)].map((_, index) => (
                <div key={index}>
                  <Skeleton className='max-w-sm mx-auto text-center group rounded transition-all duration-500 h-[300px]' />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
