import { paragraphs } from '../../assets/json/quality-control';

export default function FlowDescription() {
  return (
    <>
      <div className='container !max-w-[1000px] py-8'>
        <div className='grid md:grid-cols-2 grid-cols-1 gap-6'>
          {paragraphs.map((item, index) => (
            <div key={index} className='px-4 py-6'>
              <div className='text-center mx-auto'>
                <img
                  alt={item.title}
                  className='w-full h-[250px] object-cover rounded-md mx-auto'
                  src={require('../../assets/images/quality-control/' + item.imgName)}
                />
              </div>
              <h2 className='text-emerald-500 text-lg font-bold py-4 text-center'>
                {index + 1}. {item.title}
              </h2>
              <p className='text-justify'>{item.content}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
