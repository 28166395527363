import { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { seo } from '../assets/json/seo';
import Main from '../components/order/MainOrderMobile';
import { getByPath } from '../services/product';

const breadcrumb = [
  {
    link: '#',
    name: 'Order',
  },
];

export default function OrderMobile() {
  const { path } = useParams();

  const [product, setProduct] = useState();
  const [initBreadcrumb, setInitBreadcrumb] = useState(breadcrumb);

  useEffect(() => {
    getByPath(path)
      .then((res) => setProduct(res))
      .catch((err) =>
        toast.error('Error while loading product. Please try again!')
      );
  }, [path]);

  useEffect(() => {
    if (product?.name) {
      setInitBreadcrumb([...initBreadcrumb, { name: product.name }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product?.name]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>
            {seo.orderMobile.title}
          </title>
          <meta name='keywords' content={seo.orderMobile.keywords} />
          <meta name='description' content={seo.orderMobile.description} />
        </Helmet>
      </HelmetProvider>
      <Main data={product} initBreadcrumb={initBreadcrumb} />
    </>
  );
}
