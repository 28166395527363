export const faqType = [
  {
    id: 1,
    label: `Order`,
  },
  {
    id: 2,
    label: `Mass production`,
  },
  {
    id: 3,
    label: `Shipment`,
  },
  {
    id: 4,
    label: `Payment`,
  },
];

export const faqsData = [
  {
    type: 1,
    question: `How can I send inquiry to you?`,
    answer:
      `You can send inquiries to us through email or message (Whatsapp, Wechat…etc). The inquiries should have photos, dimensions, quantity, quality requirement, Incoterm…etc).`,
  },
  {
    type: 1,
    question: `What is your MOQ?`,
    answer:
      `Our MOQ is 100 pcs/ color/ item.`,
  },
  {
    type: 1,
    question: `How long can I receive your quotation?`,
    answer:
      `It is about 3~5 days, depending on your items.`,
  },
  {
    type: 1,
    question: `What I should do to make my designs come true?`,
    answer:
      `Please send your ideas that have images, descriptions, dimensions and other information! The more information we have, the easier we can make. After receiving your ideas, our sales and design team sketch or make photoshop to show you illustratively. And then we will make real samples until you approve.`,
  },
  {
    type: 1,
    question: `Can I customize products?`,
    answer:
      `Yes. We are very flexible for customization.`,
  },
  {
    type: 1,
    question: `Can I order samples?`,
    answer:
      `Yes. We are pleased to help you at the first step of orders.`,
  },
  {
    type: 1,
    question: `What about sample lead time?`,
    answer:
      `It is about 5~10 days, depending on how complicated samples are.`,
  },
  {
    type: 1,
    question: `Why don’t the products on the website show prices?`,
    answer:
      `We don’t show product prices because they often change according to many conditions (market needs, raw material sources…etc). Every customer purchases different quantity, we need to know specific order quantity of every customer to give the best prices.`,
  },

  {
    type: 2,
    question: `How can I place an order?`,
    answer:
      `Simply send your PO to us through email! We will check orders, make proforma invoice, packing list and sales contract then send you. After you approve, we will immediately start mass production.`,
  },
  {
    type: 2,
    question: `How long do you finish an order?`,
    answer:
      `This depends on your products and order quantity. Production time is from 35 ~ 65 days or more.`,
  },
  {
    type: 2,
    question: `How do I know my product status in mass production?`,
    answer:
      `You always know your production progress because we always update every step of production by images, videos and call conference. This makes customers feel secure and trust us.`,
  },
  {
    type: 2,
    question: `Can I customize product that is in mass production?`,
    answer:
      `Yes. We can handle customization on products in mass production. However, we suggest that you should do in the beginning of mass production or customize details that don’t affect product structure.`,
  },
  {
    type: 2,
    question: `How do you control quality?`,
    answer:
      `We comprehensively control quality from the beginning to the end. We follow ISO 2589-1 (Major: 2.5, minor: 4.0) to control raw materials. For the processes of during production, 100% checking on semi-products at every step is applied to prevent defects and wastes. And before finished products are packed in cartons, we do pre-shipment inspection based on ISO 2589-1 (Major: 2.5, minor: 4.0) to ensure that the products are within requirements without any issues. Most importantly, moisture must be from 7-12%.`,
  },
  {
    type: 2,
    question: `Are products always available?`,
    answer:
      `No. Products are unavailable. We produce based on customers’ orders.`,
  },

  {
    type: 3,
    question: `Is LCL shipment available?`,
    answer:
      `Yes. We support small quantity shipment.`,
  },
  {
    type: 3,
    question: `I don’t know how to pack my products. What kind of packing method do you use?`,
    answer:
      `We usually use polybag and desiccant silicagel for a piece/ a set, then products packed in 5-ply carton. Polybag prevents mildew very effectively.<br/>For spun bamboo products, we use bubble foam wrap to pack every piece.<br/>
      Hangtag attached if customers require.`,
  },
  {
    type: 3,
    question: `What is port of loading?`,
    answer:
      `Hai Phong port and Ho Chi Minh port.`,
  },

  {
    type: 4,
    question: `What is currency and payment term?`,
    answer:
      `As normally, our prices are quoted in USD and we are using:<br/>
      - T/T payment: 30% deposit and 70% balance must be paid after customers receive the copy of Bill of Lading<br/>
      - L/C payment: applied for the first order is valued at over $45,000
      `,
  },
];
