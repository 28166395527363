import instance from './axios';

export const sendContact = async (data) => (await instance.post('/contact/send-contact', {
  ...data
})).data;

export const sendRegisterMail = async (data) => (await instance.post('/contact/register-email', {
  ...data
})).data;

export const sendOrder = async (data) => (await instance.post('/contact/send-order', {
  ...data
})).data;
