import { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { useLocation, useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

// const itemsPage = [4, 8, 12, 24, 48];
const itemsPerPage = 8;
function NewPagination({ total }) {
  const { page } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  // We start with an empty list of items.
  // const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(1);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  // const [itemOffset, setItemOffset] = useState(0);

  // useEffect(() => {
  //   if (page && data) {
  //     const newOffset = ((page - 1) * itemsPerPage) % data.length;
  //     // console.log(
  //     //   `User requested page number ${event.selected}, which is offset ${newOffset}`
  //     // );
  //     setItemOffset(newOffset);
  //   }
  // }, [page, data, itemsPerPage]);

  useEffect(() => {
    // Fetch items from another resources.
    // if (data) {
    // const endOffset = itemOffset + itemsPerPage;
    // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    // setCurrentItems(data.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(total / itemsPerPage));
    // }
  }, [total]);

  const handlePageClick = (event) => {
    // const newOffset = (event.selected * itemsPerPage) % data.length;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`
    // );
    // setItemOffset(newOffset);

    const path = replaceTextPagePath(
      location.pathname,
      Number(event.selected) + 1
    );
    // window.scrollTo(0, 0);
    return navigate(path);
  };

  const replaceTextPagePath = (text, textReplace) => {
    return text.replace(`/${page}`, `/${textReplace}`);
  };

  return (
    <>
      {total > 8 && pageCount && (
        <div className='flex justify-center items-center gap-4'>
          <ReactPaginate
            nextLabel='>'
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            forcePage={page - 1}
            previousLabel='<'
            pageClassName=''
            pageLinkClassName='page-link px-4 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700'
            previousClassName=''
            previousLinkClassName='page-link block px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700'
            nextClassName=''
            nextLinkClassName='page-link block px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700'
            breakLabel='...'
            breakClassName='flex items-center justify-center w-10 h-10 text-emerald-500 transition-colors duration-150 border border-gray-300 focus:shadow-outline hover:bg-emerald-100'
            breakLinkClassName='page-link'
            containerClassName='inline-flex items-center'
            activeClassName=''
            activeLinkClassName='z-10 px-3 py-2 leading-tight text-black border border-emerald-300 bg-emerald-50 hover:bg-emerald-100 hover:text-emerald-700'
            renderOnZeroPageCount={null}
          />

          {/* <select
              type='text'
              name=''
              className='py-2 px-3 bg-white border border-gray-200 rounded shadow-sm appearance-none focus:outline-emerald-500'
              id=''
              defaultValue={itemsPerPage}
              onChange={(e) => {
                window.sessionStorage.setItem(`${type}-itemsPerPage`, e.target.value);
                setItemsPerPage(Number.parseInt(e.target.value));
              }}
            >
              {itemsPage.map((item, index) => {
                return (
                  <option key={index} value={item}>
                    {item}
                  </option>
                );
              })}
            </select> */}
        </div>
      )}
    </>
  );
}

export default NewPagination;
