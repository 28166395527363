import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Drawer from 'react-modern-drawer';

//import styles 👇
import 'react-modern-drawer/dist/index.css';

import { navs } from '../../../assets/json/navigate';
import Skeleton from 'react-loading-skeleton';

export default function DrawerNav({
  isOpenDrawer,
  setIsOpenDrawer,
  categories,
  materials,
  number,
}) {
  const location = useLocation();

  const [pathname, setPathname] = useState('');
  const [navigates, setNavigates] = useState(navs);

  useEffect(() => {
    if (location) setPathname(location.pathname || '');
  }, [location]);

  useEffect(() => {
    const arr = JSON.parse(JSON.stringify(navigates));
    arr.forEach((item) => {
      switch (item.id) {
        case 'category':
          item.class = item.id + number;
          item.dropdown = categories || [];
          break;
        case 'material':
          item.class = item.id + number;
          item.dropdown = materials || [];
          break;
        default:
          break;
      }
    });

    setNavigates(arr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories, materials]);

  const [size, setSize] = useState('0%');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 376) setSize('100%');
      else if (window.innerWidth < 567) setSize('70%');
      else setSize('40%');
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (window.innerWidth < 376) setSize('100%');
    else if (window.innerWidth < 567) setSize('70%');
    else setSize('30%');
  }, []);

  useEffect(() => {
    if (isOpenDrawer) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'auto';
  }, [isOpenDrawer]);

  const handleToggleDropdown = (e, id) => {
    e?.target?.classList?.toggle('!rotate-180');
    const dropdownElement = document.querySelector('.' + id);
    dropdownElement?.classList?.toggle('active-dropdown');
  };

  return (
    <>
      <Drawer
        open={isOpenDrawer}
        onClose={() => setIsOpenDrawer(false)}
        direction='right'
        size={size}
        className='!min-h-screen !h-full overflow-auto'
      >
        <div className='py-8 min-h-screen flex flex-col justify-center mt-4 mb-12'>
          <div>
            <ul>
              <li className='border border-[#ececec] bg-white hover:bg-black/5 transition-all text-[.9rem] relative'>
                <div>
                  <div className='flex items-center justify-between'>
                    <Link
                      to={'/'}
                      className={
                        'uppercase pl-5 py-3 tracking-wide block w-full font-bold group-hover:text-emerald-500' +
                        (pathname === '/'
                          ? ' text-emerald-500'
                          : ' text-gray-500')
                      }
                      onClick={() => setIsOpenDrawer(false)}
                    >
                      Home
                    </Link>
                  </div>
                </div>
                <div
                  className='absolute -top-full right-0 p-2 cursor-pointer hover:rotate-90 transition duration-500 hover:text-emerald-500'
                  onClick={() => setIsOpenDrawer(false)}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth={1.5}
                    stroke='currentColor'
                    className='w-6 h-6'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M6 18L18 6M6 6l12 12'
                    />
                  </svg>
                </div>
              </li>
              {navigates &&
                navigates.map((item, index) => (
                  <li
                    key={index}
                    className='border border-[#ececec] bg-white hover:bg-black/5 transition-all text-[.9rem]'
                  >
                    <div>
                      <div className='flex items-center justify-between'>
                        <Link
                          to={item.url}
                          className={
                            'uppercase pl-5 py-3 tracking-wide block w-full font-bold group-hover:text-emerald-500' +
                            (pathname.indexOf(item.url) >= 0
                              ? ' text-emerald-500'
                              : ' text-gray-500')
                          }
                          onClick={() => setIsOpenDrawer(false)}
                        >
                          {item.title}
                        </Link>
                        {item.isDropdown && (
                          <div
                            className={
                              'w-11 h-auto mr-1 text-center select-none rotate-0 transition py-3 px-3 hover:text-emerald-500'
                            }
                            onClick={(e) => handleToggleDropdown(e, item.class)}
                          >
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              fill='none'
                              viewBox='0 0 24 24'
                              strokeWidth='1.5'
                              stroke='currentColor'
                              className='w-5 h-5 mx-auto transition rotate-0'
                            >
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                d='M19.5 8.25l-7.5 7.5-7.5-7.5'
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              />
                            </svg>
                          </div>
                        )}
                      </div>
                      {item.isDropdown && item.dropdown && (
                        <ul
                          className={
                            'py-2 transition-all duration-500 shadow border rounded z-[9] left-0 top-full bg-black/5 opacity-100 dropdown-drawer ' +
                            item.class
                          }
                        >
                          {item.dropdown ? (
                            item?.dropdown?.map((drop, i) => (
                              <li
                                key={i}
                                className='py-2 pl-8'
                                onClick={() => setIsOpenDrawer(false)}
                              >
                                <Link
                                  to={item.url + '/' + drop.path + '/page/1'}
                                  className={
                                    'font-bold hover:text-emerald-500' +
                                    (pathname.indexOf(drop.path) >= 0
                                      ? ' text-emerald-500'
                                      : ' text-gray-500')
                                  }
                                >
                                  {drop.name}
                                </Link>
                              </li>
                            ))
                          ) : (
                            <Skeleton height={20} />
                          )}
                        </ul>
                      )}
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </Drawer>
    </>
  );
}
