import { Link } from 'react-router-dom';
import { video } from '../../../assets/json/home';

export default function VideoSession() {
  return (
    <>
      <span
        id='video'
        className='h-[75px] -mt-[75px] md:h-[165px] md:-mt-[165px] invisible block'
      ></span>
      <div className='container min-h-[200px] !py-8 rounded'>
        <div className='mx-auto'>
          <div className='flex flex-wrap md:flex-nowrap mx-auto text-center items-center'>
            <div className='w-full md:pr-16 md:order-1 order-2 mb-4'>
              <div
                className='text-black font-medium text-base text-left mb-4'
                dangerouslySetInnerHTML={{ __html: video.caption.content }}
              ></div>
              <Link
                data-modal-hide='staticModal'
                type='button'
                to={video.caption.button.to}
                className='text-white bg-emerald-500 hover:brightness-95 focus:ring-4 focus:outline-none focus:ring-emerald-300 font-medium rounded-full text-sm px-8 py-2.5 text-center'
              >
                {video.caption.button.title}
              </Link>
            </div>
            <div className='w-full md:min-w-[560px] mb-4 text-right md:order-2 order-1'>
              <iframe
                className='mx-auto rounded w-full md:min-w-[560px]'
                height='315'
                src={video.src}
                title='YouTube video player'
                frameBorder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen;'
                allowFullScreen='allowfullscreen'
                rel='0'
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
