import { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useOutletContext } from 'react-router';
import BadgeSession from '../components/home/badge/BadgeSession';
import CategorySession from '../components/home/category/CategorySession';
import MaterialSession from '../components/home/material/MaterialSession';
import NewProductSession from '../components/home/product/NewProductSession';
import Slideshow from '../components/home/slideshow/Slideshow';
import Why from '../components/home/why/Why';
import ButtonBottom from '../components/shared/other/ButtonBottom';
import { listBadges } from '../assets/json/badges';
import { seo } from '../assets/json/seo';
import VideoSession from '../components/home/video/VideoSession';

export default function Home() {
  const { materials, categories } = useOutletContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{seo.home.title}</title>
          <meta name='keywords' content={seo.home.keywords} />
          <meta name='description' content={seo.home.description} />
        </Helmet>
      </HelmetProvider>
      <div className='text-lg font-bold text-emerald-500'>
        <Slideshow />
        {listBadges && listBadges?.length > 0 && (
          <ButtonBottom linkTo={'#badges-1'} />
        )}
        <BadgeSession id={'badges-1'} listBadges={listBadges} startIndex={0} />
        <VideoSession />
        <ButtonBottom linkTo={'#materials'} />
        <MaterialSession materials={materials} />
        <ButtonBottom linkTo={'#why'} />
        <Why />
        <ButtonBottom linkTo={'#new'} />
        <NewProductSession />
        {listBadges && listBadges?.length > 0 && (
          <ButtonBottom linkTo={'#badges-2'} />
        )}
        <BadgeSession id={'badges-2'} listBadges={listBadges} startIndex={3} />
        <ButtonBottom linkTo={'#categories'} />
        <CategorySession categories={categories} />
        {/* <CustomerSession customers={customers} /> */}
      </div>
    </>
  );
}
