import Skeleton from 'react-loading-skeleton';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper';

import { why } from '../../../assets/json/why';

import './styles/why.css';

const pagination = {
  clickable: true,
  bulletClass: 'swiper-pagination-bullet ',
  renderBullet: (index, className) => {
    return `<span class="${className} inline-block !w-[20px] !h-[20px] border-[7px] border-transparent rounded-full !ml-0 !mr-[10px] transition duration-500 !bg-transparent">
      <span class='h-full w-full block rounded-full bg-gray-600'></span>
    </span>`;
  },
};

export default function Why({containerClassName='container', isWidthClasses=true}) {
  // useEffect(() => {
  //   setHeight();
  // }, []);

  // const setHeight = () => {
  //   const whyItemElements = document.querySelectorAll('.why-item');
  //   if (whyItemElements && whyItemElements?.length > 0) {
  //     whyItemElements.forEach(
  //       (item) => (item.style.height = item.offsetWidth + 'px')
  //     );
  //   }
  // };

  // useEffect(() => {
  //   const handleResize = () => setHeight();

  //   window.addEventListener('resize', handleResize);

  //   return () => window.removeEventListener('resize', handleResize);
  // }, []);

  return (
    <>
      <span
        id='why'
        className='h-[75px] -mt-[75px] md:h-[165px] md:-mt-[165px] invisible block'
      ></span>
      <div className={'min-h-[200px] !py-8 ' + containerClassName}>
        <div className={'mx-auto why sm:w-3/4 ' + (isWidthClasses ? 'lg:w-full xl:w-9/12' : ' lg:w-[900px] md:w-10/12'
        )}>
          {why ? (
            <h2 className={'uppercase text-gray-500 font-bold tracking-wide text-center pb-8 ' + (isWidthClasses?'text-4xl':'text-3xl')}>
              {why.title}
            </h2>
          ) : (
            <Skeleton containerClassName='pb-4 block' height={45} />
          )}
          <div className='lg:grid hidden grid-cols-1 lg:grid-cols-3 gap-8'>
            {why?.whys?.map((item, index) => (
              <div
                key={index}
                className={'why-item mx-auto text-center group rounded-full transition-all duration-500 border-4 border-emerald-500 p-8 bg-white hover:shadow-xl overflow-hidden ' + (isWidthClasses?'w-[320px] h-[320px]' : 'w-[285px] h-[285px]')}
              >
                <div className=''>
                  <img
                    className='w-[45px] h-[45px] object-cover mx-auto'
                    src={require('../../../assets/images/why-us/' +
                      item.nameFile)}
                    alt=''
                  />
                  <h3 className='mt-2 text-lg text-emerald-500 font-bold whitespace-nowrap'>{item.title}</h3>
                </div>
                <div className='border-dashed border-2 border-emerald-500 w-2/4 mx-auto mt-6 mb-3'></div>
                <div
                  className={'text-icon font-semibold max-h-[50%] overflow-hidden ' + (isWidthClasses?'text-base':'text-sm')}
                  title={item.content}
                >
                  {item.content}
                </div>
              </div>
            ))}
          </div>
          <Swiper
            className='block lg:!hidden'
            slidesPerView={1}
            modules={[Pagination, Autoplay]}
            pagination={pagination}
            spaceBetween={30}
            autoplay={{
              delay: 1000,
              pauseOnMouseEnter: true,
            }}
            loop
          >
            {why?.whys?.map((item, index) => (
              <SwiperSlide key={index}>
                <div className='why-item w-[320px] h-[320px] mx-auto text-center group rounded-full transition-all duration-500 border-4 border-emerald-500 p-8 bg-white hover:shadow-xl overflow-hidden'>
                  <div className=''>
                    <img
                      className='w-[45px] h-[45px] object-cover mx-auto'
                      src={require('../../../assets/images/why-us/' +
                        item.nameFile)}
                      alt=''
                    />
                    <h3 className='mt-2 text-lg text-emerald-500 font-bold whitespace-nowrap'>{item.title}</h3>
                  </div>
                  <div className='border-dashed border-2 border-emerald-500 w-2/4 mx-auto mt-6 mb-3'></div>
                  <div
                    className='text-icon font-semibold text-base max-h-[50%] overflow-hidden'
                    title={item.content}
                  >
                    {item.content}
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  );
}
