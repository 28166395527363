import { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { seo } from '../assets/json/seo';
import Main from '../components/contact/MainContact';


export default function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>
            {seo.contact.title}
          </title>
          <meta name='keywords' content={seo.contact.keywords} />
          <meta name='description' content={seo.contact.description} />
        </Helmet>
      </HelmetProvider>
      <Main />
    </>
  )
}
