import Flickity from 'react-flickity-component';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { search as searchSlides } from '../../../services/slide';
// import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';

// flickity 👇
import 'flickity/css/flickity.css';

const flickityOptions = {
  cellAlign: 'center',
  imagesLoaded: true,
  lazyLoad: 1,
  freeScroll: false,
  wrapAround: true,
  autoPlay: 6000,
  pauseAutoPlayOnHover: true,
  prevNextButtons: true,
  contain: true,
  adaptiveHeight: true,
  dragThreshold: 10,
  percentPosition: true,
  pageDots: true,
  rightToLeft: false,
  draggable: true,
  selectedAttraction: 0.1,
  parallax: 0,
  friction: 0.6,
};

export default function Slideshow() {
  const [slides, setSlides] = useState([]);

  useEffect(() => {
    searchSlides({
      page: 1,
      pageSize: 5,
    }).then((res) => setSlides(res));
    // .catch((err) => toast.error('Error while loading categories!'));
  }, []);

  return (
    <>
      {slides && slides?.length > 0 ? (
        <Flickity
          className={'carousel'} // default ''
          elementType={'div'} // default 'div'
          options={flickityOptions} // takes flickity options {}
          disableImagesLoaded={false} // default false
          reloadOnUpdate // default false
          static // default false
        >
          {slides.map((item) => (
            // <div key={item._id} className='p-0 pt-[570px] w-full m-0 relative'>
            <div key={item._id} className='p-0 h-[533px] w-full m-0 relative'>
              <div className='fill z-0'>
                <div className='fill overflow-hidden'>
                  <div
                    className='fill bg-fill'
                    // style={{
                    //   background: `url(${item.backgroundImage})`,
                    // }}
                  >
                    <img className='w-full object-cover object-left h-full md:h-auto md:object-contain md:object-[initial]' src={item.backgroundImage} alt="" />
                  </div>
                </div>
              </div>
              {/* <div className='absolute top-0 left-0 w-full h-full bg-black opacity-40'></div> */}
              <div className='absolute w-full container top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
                <div className='lg:w-[650px] sm:w-[600px] w-[100%] text-center sm:text-left font-normal text-black relative bg-inherit overflow-hidden rounded-md z-[0]'>
                  <div className='p-10 sm:p-12'>
                    {/* <h1 className='text-4xl sm:text-5xl font-bold mb-5 text-emerald-500'>
                      {item.title}
                    </h1> */}
                    <p
                      className='mb-3 text-black text-lg sm:text-3xl'
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    ></p>
                    <Link
                      to={item.redirectTo}
                      // type='button'
                      className='py-[14px] px-[36px] rounded-full font-medium uppercase text-sm md:text-[1.2rem] inline-flex justify-center items-center relative bg-emerald-500 hover:scale-95 transition'
                    >
                      <span
                        className=' text-white '
                        // style={{ mixBlendMode: 'difference' }}
                      >
                        {item.contentLink}
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Flickity>
      ) : (
        <div className='p-0 pt-[533px] w-full m-0 relative'>
          <Skeleton
            containerClassName='w-full h-full fill !block'
            className='w-full h-full !block'
          />
        </div>
      )}
    </>
  );
}
