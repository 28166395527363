import { useEffect, useState } from 'react';
import Filter from '../search/Filter';
import Breadcrumb from '../shared/breadcrumb/Breadcrumb';
import OrderModal from '../shared/modal/OrderModal';
// import DescriptionDetail from './DescriptionDetail';
import GalleryDetail from './GalleryDetail';
import InfoDetail from './InfoDetail';
import LightBox from './LightBox';
import RelatedProduct from './RelatedProduct';

export default function Main({ data, initBreadcrumb, categories }) {
  const [openLightBox, setOpenLightBox] = useState(false);
  const [selectedImg, setSelectedImg] = useState('');
  const [isOpenModal, setIsOpenModal] = useState(false);

  useEffect(() => {
    if (data) {
      setSelectedImg(data.gallery[0]);
    }
  }, [data]);

  return (
    <>
      <OrderModal
        open={isOpenModal}
        setClose={setIsOpenModal}
        selectedProduct={data}
      />
      <div className='min-h-[300px] mb-8'>
        <div className='bg-slate-100'>
          <div className='min-h-[60px] py-3 flex justify-between items-center md:flex-row md:gap-0 gap-4 flex-col container'>
            <div>
              <Breadcrumb breadcrumbs={initBreadcrumb} />
            </div>
          </div>
        </div>
        <div className='flex flex-wrap items-start container my-6'>
          <GalleryDetail
            data={data}
            setSelectedImg={setSelectedImg}
            setOpenLightBox={setOpenLightBox}
            selectedImg={selectedImg}
          />
          <InfoDetail data={data} setIsOpenModal={setIsOpenModal} />
          <div className='md:w-2/12 w-full'>
            <Filter
              data={categories}
              title='Categories'
              keyword={data?.category?._id}
            />
          </div>
        </div>
        <div className='my-4 py-4 border-t container'>
          {/* <DescriptionDetail data={data} /> */}
          <RelatedProduct data={data} />
        </div>
      </div>

      <LightBox
        data={data}
        openLightBox={openLightBox}
        setOpenLightBox={setOpenLightBox}
        selectedImg={selectedImg}
      />
    </>
  );
}
