import { about } from '../../assets/json/about';
import Why from '../home/why/Why';
import Content from './Content';
import Vision from './Vision';

import imgSlide from '../../assets/images/about/slide.jpg';
import Customer from './Customer';

export default function Main() {
  return (
    <>
      <div
        className='hidden sm:block pt-[80vh] bg-cover bg-no-repeat bg-center sm:mx-0'
        style={{
          backgroundImage: `url(${imgSlide})`,
        }}
      ></div>
      <img className='px-[15px] sm:hidden' src={imgSlide} alt='' />
      <div className='my-8 container'>
        <h2 className='uppercase text-gray-500 font-bold tracking-wide text-center pb-8 text-4xl'>
          {about.title}
        </h2>
        <Vision />
        <Why containerClassName='' isWidthClasses={true} />
        <Content />
        <Customer />
      </div>
    </>
  );
}
