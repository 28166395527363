import { useEffect, useRef } from 'react';
import HeaderBottom from './HeaderBottom';
import HeaderTop from './HeaderTop';

export default function Header({ categories, materials, updates }) {
  // const headerRef = useRef();
  const headerTopRef = useRef();
  const headerBottomRef = useRef();

  // useEffect(() => {
  //   if (headerRef) {
  //     const handleScroll = (e) => {
  //       if (window.scrollY > headerRef.current.offsetHeight + 100) {
  //         // headerRef.current.classList.add('stuck');
  //         if (window.innerWidth > 768) {
  //           // headerRef.current.style.height = '133px';
  //           headerTopRef.current.style.display = 'none';
  //         } else {
  //           headerRef.current.style.height = 'auto';
  //           headerTopRef.current.style.height = 'auto';
  //         }
  //       } else if (window.scrollY <= 0) {
  //         headerRef.current.classList.remove('stuck');
  //         headerTopRef.current.style.height = 'auto';
  //         headerRef.current.style.height = 'auto';
  //         headerTopRef.current.style.display = 'flex';
  //       }
  //     };

  //     window.addEventListener('scroll', handleScroll);

  //     return () => window.removeEventListener('scroll', handleScroll);
  //   }
  // }, []);

  // useEffect(() => {
  //   if (window.innerWidth > 768)
  //   {
  //     if (window.scrollY > 100)
  //     {
  //       headerTopRef.current.style.display = 'none';
  //     }
  //   }
  // }, []);

  useEffect(() => {
    const handleScroll = (e) => {
      if (window.scrollY > 100) {
        // if (window.innerWidth > 768) {
        // } else {
        //   // headerTopRef.current.style.height = 'auto';
        // }
        headerBottomRef.current.classList.add('shadow');
        headerTopRef.current.classList.add('shadow');
      } else if (window.scrollY <= 0) {
        headerBottomRef.current.classList.remove('shadow');
        headerTopRef.current.classList.remove('shadow');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      {/* <div
        className='sticky-jump z-[100] bg-white'
        // style={{background: 'linear-gradient(180deg, rgba(49,196,141,1) 0%, rgba(255,255,255,1) 50%)'}}
      >
        <div
          ref={headerRef}
          className='header-wrapper bg-white'
          // style={{background: 'linear-gradient(180deg, rgba(49,196,141,1) 0%, rgba(255,255,255,1) 50%)'}}
        ></div>
      </div> */}
      <HeaderTop
        headerTopRef={headerTopRef}
        categories={categories}
        updates={updates}
        materials={materials}
      />
      <HeaderBottom
        headerBottomRef={headerBottomRef}
        categories={categories}
        updates={updates}
        materials={materials}
      />
    </>
  );
}
