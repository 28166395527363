import CircleType from 'circletype';
import { useEffect, useRef } from 'react';

import { flowcharts } from '../../assets/json/howwemake';

export default function MainFlow() {
  const text1Ref = useRef();
  const text2Ref = useRef();
  const text3Ref = useRef();
  const text4Ref = useRef();

  // text 1
  useEffect(() => {
    if (text1Ref.current)
      new CircleType(text1Ref.current).radius(110).forceWidth(true);
    setTimeout(() => {
      text1Ref.current.style.transform = 'rotate(90deg)';
    });
  }, []);

  // text 2
  useEffect(() => {
    if (text2Ref.current)
      new CircleType(text2Ref.current).radius(110).dir(-1).forceWidth(true);

    setTimeout(() => {
      text2Ref.current.style.transform = 'rotate(90deg)';
    });
  }, []);

  // text 3
  useEffect(() => {
    if (text3Ref.current)
      new CircleType(text3Ref.current).radius(100).forceWidth(true);
    setTimeout(() => {
      text3Ref.current.style.transform = 'rotate(90deg)';
    });
  }, []);

  // text 4
  useEffect(() => {
    if (text4Ref.current)
      new CircleType(text4Ref.current).radius(100).forceWidth(true);
    setTimeout(() => {
      text4Ref.current.style.transform = 'rotate(90deg)';
    });
  }, []);

  return (
    <>
      <div className='container min-h-screen !w-[1200px] mx-auto pt-8 hidden lg:block'>
        <div className='grid grid-cols-5 grid-rows-3 items-center'>
          {/* 1 */}
          <div className='h-52 w-full flex justify-end items-center'>
            <div className='h-3 w-1/2 bg-gray-200'></div>
          </div>
          <div className='h-52 w-full flex justify-center items-center'>
            <div className='h-3 w-full bg-gray-200 relative'>
              <div
                className='absolute h-full w-1/2 left-0'
                style={{
                  background: flowcharts[0].color,
                }}
              >
                <div className='absolute -top-[400%] left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center z-[-1]'>
                  <div
                    className='w-[3px] h-4 order-last'
                    style={{
                      background: flowcharts[0].borderColor,
                    }}
                  ></div>
                  <div
                    className='w-22 h-22 border-b-[5px] rounded-b-full'
                    style={{ borderColor: flowcharts[0].borderColor }}
                  >
                    <div className='w-20 h-20 rounded-full flex items-center justify-center text-white font-bold m-auto p-3'>
                      <img
                        src={require('../../assets/images/icons/flowcharts/' +
                          flowcharts[0].nameImg)}
                        alt=''
                      />
                    </div>
                  </div>
                </div>
                <h3
                  className='absolute top-full left-1/2 -translate-x-1/2 font-bold text-base text-bg-transparent text-center'
                  style={{
                    background: flowcharts[0].color,
                  }}
                >
                  {flowcharts[0].title}
                </h3>
              </div>

              <div className='absolute -top-1/2 -translate-y-full text-sm -right-[30%] -translate-x-1/2'>
                {flowcharts[0].line1}
              </div>
              <div className='absolute top-[130%] text-sm -right-[45%] -translate-x-1/2'>
                {flowcharts[0].line2}
              </div>
            </div>
          </div>
          <div className='h-52 w-full flex justify-center items-center'>
            <div className='h-3 w-full bg-gray-200 relative'>
              <div
                className='absolute h-full w-1/2 left-1/4'
                style={{
                  background: flowcharts[1].color,
                }}
              >
                <div className='absolute -top-[400%] left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center z-[-1]'>
                  <div
                    className='w-[3px] h-4 order-last'
                    style={{
                      background: flowcharts[1].borderColor,
                    }}
                  ></div>
                  <div
                    className='w-22 h-22 border-b-[5px] rounded-b-full'
                    style={{ borderColor: flowcharts[1].borderColor }}
                  >
                    <div className='w-20 h-20 rounded-full flex items-center justify-center text-white font-bold m-auto p-3'>
                      <img
                        src={require('../../assets/images/icons/flowcharts/' +
                          flowcharts[1].nameImg)}
                        alt=''
                      />
                    </div>
                  </div>
                </div>
                <h3
                  className='absolute top-full left-1/2 -translate-x-1/2 font-bold text-base text-bg-transparent text-center'
                  style={{
                    background: flowcharts[1].color,
                  }}
                >
                  {flowcharts[1].title}
                </h3>
              </div>

              <div className='absolute -top-1/2 -translate-y-full text-sm -right-[55%] -translate-x-1/2'>
                {flowcharts[1].line1}
              </div>
              <div className='absolute top-[130%] text-sm -right-[70%] -translate-x-1/2'>
                {flowcharts[1].line2}
              </div>
            </div>
          </div>
          <div className='h-52 w-full flex justify-center items-center'>
            <div className='h-3 w-full bg-gray-200 relative'>
              <div
                className='absolute h-full w-1/2 left-2/4'
                style={{
                  background: flowcharts[2].color,
                }}
              >
                <div className='absolute -top-[400%] left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center z-[-1]'>
                  <div
                    className='w-[3px] h-4 order-last'
                    style={{
                      background: flowcharts[2].borderColor,
                    }}
                  ></div>
                  <div
                    className='w-22 h-22 border-b-[5px] rounded-b-full'
                    style={{
                      borderColor: flowcharts[2].borderColor,
                    }}
                  >
                    <div className='w-20 h-20 rounded-full flex items-center justify-center text-white font-bold m-auto p-3'>
                      <img
                        src={require('../../assets/images/icons/flowcharts/' +
                          flowcharts[2].nameImg)}
                        alt=''
                      />
                    </div>
                  </div>
                </div>
                <h3
                  className='absolute top-full left-1/2 -translate-x-1/2 font-bold text-base text-bg-transparent text-center'
                  style={{
                    background: flowcharts[2].color,
                  }}
                >
                  {flowcharts[2].title}
                </h3>
              </div>
            </div>
          </div>
          <div className='h-52 w-full flex justify-start items-end'>
            <div className='h-[calc(50%+0.375rem)] w-1/2 bg-transition border-t-[.75rem] border-r-[.75rem] border-gray-200 rounded-tr-full relative'>
              <div
                ref={text1Ref}
                className='absolute text-center left-[10%] text-sm bottom-[-18px]'
              >
                {flowcharts[2].line1}
              </div>
              <div className='absolute rotate-90 left-[110%] bottom-[-6px] text-sm'>
                {flowcharts[2].line2}
              </div>
            </div>
          </div>

          {/* 2 */}
          <div className='h-52 w-full flex justify-end items-end'>
            <div className='h-[calc(50%+0.375rem)] w-1/2 bg-transition border-t-[.75rem] border-l-[.75rem] border-gray-200 rounded-tl-full relative'>
              <div className='absolute rotate-90 bottom-[-8px] -left-[40%] text-sm'>
                {flowcharts[5].line1}
              </div>
              <div
                ref={text2Ref}
                className='absolute left-[-35%] text-sm text-center bottom-[-18px]'
              >
                {flowcharts[5].line2}
              </div>
            </div>
          </div>
          <div className='h-52 w-full flex justify-center items-center'>
            <div className='h-3 w-full bg-gray-200 relative'>
              <div
                className='absolute h-full w-1/2 left-0'
                style={{
                  background: flowcharts[5].color,
                }}
              >
                <div className='absolute -top-[400%] left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center z-[-1]'>
                  <div
                    className='w-[3px] h-4 order-last'
                    style={{
                      background: flowcharts[5].borderColor,
                    }}
                  ></div>
                  <div
                    className='w-22 h-22 border-b-[5px] rounded-b-full'
                    style={{
                      borderColor: flowcharts[5].borderColor,
                    }}
                  >
                    <div className='w-20 h-20 rounded-full flex items-center justify-center text-white font-bold m-auto p-3'>
                      <img
                        src={require('../../assets/images/icons/flowcharts/' +
                          flowcharts[5].nameImg)}
                        alt=''
                      />
                    </div>
                  </div>
                </div>
                <h3
                  className='absolute top-full left-1/2 -translate-x-1/2 font-bold text-base text-bg-transparent text-center'
                  style={{
                    background: flowcharts[5].color,
                  }}
                >
                  {flowcharts[5].title}
                </h3>
              </div>
            </div>
          </div>
          <div className='h-52 w-full flex justify-center items-center'>
            <div className='h-3 w-full bg-gray-200 relative'>
              <div
                className='absolute h-full w-1/2 left-1/4'
                style={{
                  background: flowcharts[4].color,
                }}
              >
                <div className='absolute -top-[400%] left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center z-[-1]'>
                  <div
                    className='w-[3px] h-4 order-last'
                    style={{
                      background: flowcharts[4].borderColor,
                    }}
                  ></div>
                  <div
                    className='w-22 h-22 border-b-[5px] rounded-b-full'
                    style={{ borderColor: flowcharts[4].borderColor }}
                  >
                    <div className='w-20 h-20 rounded-full flex items-center justify-center text-white font-bold m-auto p-3'>
                      <img
                        src={require('../../assets/images/icons/flowcharts/' +
                          flowcharts[4].nameImg)}
                        alt=''
                      />
                    </div>
                  </div>
                </div>
                <h3
                  className='absolute top-full left-1/2 -translate-x-1/2 font-bold text-base text-bg-transparent text-center'
                  style={{
                    background: flowcharts[4].color,
                  }}
                >
                  {flowcharts[4].title}
                </h3>
              </div>

              <div className='absolute -top-1/2 -translate-y-full text-sm -left-[12%] -translate-x-1/2'>
                {flowcharts[4].line1}
              </div>
              <div className='absolute top-[130%] text-sm -left-[12%] -translate-x-1/2'>
                {flowcharts[4].line2}
              </div>
            </div>
          </div>
          <div className='h-52 w-full flex justify-center items-center'>
            <div className='h-3 w-full bg-gray-200 relative'>
              <div
                className='absolute h-full w-1/2 left-2/4'
                style={{
                  background: flowcharts[3].color,
                }}
              >
                <div className='absolute -top-[400%] left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center z-[-1]'>
                  <div
                    className='w-[3px] h-4 order-last'
                    style={{
                      background: flowcharts[3].borderColor,
                    }}
                  ></div>
                  <div
                    className='w-22 h-22 border-b-[5px] rounded-b-full'
                    style={{
                      borderColor: flowcharts[3].borderColor,
                    }}
                  >
                    <div className='w-20 h-20 rounded-full flex items-center justify-center text-white font-bold m-auto p-3'>
                      <img
                        src={require('../../assets/images/icons/flowcharts/' +
                          flowcharts[3].nameImg)}
                        alt=''
                      />
                    </div>
                  </div>
                </div>
                <h3
                  className='absolute top-full left-1/2 -translate-x-1/2 font-bold text-base text-bg-transparent text-center'
                  style={{
                    background: flowcharts[3].color,
                  }}
                >
                  {flowcharts[3].title}
                </h3>
              </div>

              <div className='absolute -top-1/2 -translate-y-full text-sm left-[12%] -translate-x-1/2'>
                {flowcharts[3].line1}
              </div>
              <div className='absolute top-[130%] text-sm left-[15%] -translate-x-1/2'>
                {flowcharts[3].line2}
              </div>
            </div>
          </div>
          <div className='h-52 w-full flex justify-start items-start'>
            <div className='h-[calc(50%+0.375rem)] w-1/2 bg-transition border-b-[.75rem] border-r-[.75rem] border-gray-200 rounded-br-full'></div>
          </div>

          {/* 3 */}
          <div className='h-52 w-full flex justify-end items-start'>
            <div className='h-[calc(50%+0.375rem)] w-1/2 bg-transition border-b-[.75rem] border-l-[.75rem] border-gray-200 rounded-bl-full'></div>
          </div>
          <div className='h-52 w-full flex justify-center items-center'>
            <div className='h-3 w-full bg-gray-200 relative'>
              <div
                className='absolute h-full w-1/2 left-0'
                style={{
                  background: flowcharts[6].color,
                }}
              >
                <div className='absolute -top-[400%] left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center z-[-1]'>
                  <div
                    className='w-[3px] h-4 order-last'
                    style={{
                      background: flowcharts[6].borderColor,
                    }}
                  ></div>
                  <div
                    className='w-22 h-22 border-b-[5px] rounded-b-full'
                    style={{
                      borderColor: flowcharts[6].borderColor,
                    }}
                  >
                    <div className='w-20 h-20 rounded-full flex items-center justify-center text-white font-bold m-auto p-3'>
                      <img
                        src={require('../../assets/images/icons/flowcharts/' +
                          flowcharts[6].nameImg)}
                        alt=''
                      />
                    </div>
                  </div>
                </div>
                <h3
                  className='absolute top-full left-1/2 -translate-x-1/2 font-bold text-base text-bg-transparent text-center'
                  style={{
                    background: flowcharts[6].color,
                  }}
                >
                  {flowcharts[6].title}
                </h3>
              </div>

              <div className='absolute -top-1/2 -translate-y-full text-sm -right-[3%] -translate-x-1/2'>
                {flowcharts[6].line1}
              </div>
              <div className='absolute top-[130%] text-sm -right-[40%] -translate-x-1/2'>
                {flowcharts[6].line2}
              </div>
            </div>
          </div>
          <div className='h-52 w-full flex justify-center items-center'>
            <div className='h-3 w-full bg-gray-200 relative'>
              <div
                className='absolute h-full w-1/2 left-1/4'
                style={{
                  background: flowcharts[7].color,
                }}
              >
                <div className='absolute -top-[400%] left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center z-[-1]'>
                  <div
                    className='w-[3px] h-4 order-last'
                    style={{
                      background: flowcharts[7].borderColor,
                    }}
                  ></div>
                  <div
                    className='w-22 h-22 border-b-[5px] rounded-b-full'
                    style={{
                      borderColor: flowcharts[7].borderColor,
                    }}
                  >
                    <div className='w-20 h-20 rounded-full flex items-center justify-center text-white font-bold m-auto p-3'>
                      <img
                        src={require('../../assets/images/icons/flowcharts/' +
                          flowcharts[7].nameImg)}
                        alt=''
                      />
                    </div>
                  </div>
                </div>
                <h3
                  className='absolute top-full left-1/2 -translate-x-1/2 font-bold text-base text-bg-transparent text-center'
                  style={{
                    background: flowcharts[7].color,
                  }}
                >
                  {flowcharts[7].title}
                </h3>
              </div>
              <div className='absolute -top-1/2 -translate-y-full text-sm right-[-27%] -translate-x-1/2'>
                {flowcharts[7].line1}
              </div>
              <div className='absolute top-[130%] text-sm right-[-68%] -translate-x-1/2'>
                {flowcharts[7].line2}
              </div>
            </div>
          </div>
          <div className='h-52 w-full flex justify-center items-center'>
            <div className='h-3 w-full bg-gray-200 relative'>
              <div
                className='absolute h-full w-1/2 left-2/4'
                style={{
                  background: flowcharts[8].color,
                }}
              >
                <div className='absolute -top-[400%] left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center z-[-1]'>
                  <div
                    className='w-[3px] h-4 order-last'
                    style={{
                      background: flowcharts[8].borderColor,
                    }}
                  ></div>
                  <div
                    className='w-22 h-22 border-b-[5px] rounded-b-full'
                    style={{
                      borderColor: flowcharts[8].borderColor,
                    }}
                  >
                    <div className='w-20 h-20 rounded-full flex items-center justify-center text-white font-bold m-auto p-3'>
                      <img
                        src={require('../../assets/images/icons/flowcharts/' +
                          flowcharts[8].nameImg)}
                        alt=''
                      />
                    </div>
                  </div>
                </div>
                <h3
                  className='absolute top-full left-1/2 -translate-x-1/2 font-bold text-base text-bg-transparent text-center'
                  style={{
                    background: flowcharts[8].color,
                  }}
                >
                  {flowcharts[8].title}
                </h3>
              </div>
            </div>
          </div>
          <div className='h-52 w-full flex justify-start items-end'>
            <div className='h-[calc(50%+0.375rem)] w-1/2 bg-transition border-t-[.75rem] border-r-[.75rem] border-gray-200 rounded-tr-full relative'>
              <div
                ref={text3Ref}
                className='absolute text-center left-[3%] text-sm bottom-[-24px]'
              >
                {/* {flowcharts[8].line1} */}
              </div>
              <div
                ref={text4Ref}
                className='absolute left-[68%] bottom-[-16px] text-sm'
              >
                {/* {flowcharts[8].line2} */}
              </div>
            </div>
          </div>

          {/* 4 */}
          <div></div>
          <div></div>
          <div className='h-52 w-full flex justify-center items-center'>
            <div className='h-3 w-full bg-gray-200 relative'>
              <div
                className='absolute h-full w-1/2 left-1/4'
                style={{
                  background: flowcharts[10].color,
                }}
              >
                <div className='absolute -top-[400%] left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center z-[-1]'>
                  <div
                    className='w-[3px] h-4 order-last'
                    style={{
                      background: flowcharts[10].borderColor,
                    }}
                  ></div>
                  <div
                    className='w-22 h-22 border-b-[5px] rounded-b-full'
                    style={{ borderColor: flowcharts[10].borderColor }}
                  >
                    <div className='w-20 h-20 rounded-full flex items-center justify-center text-white font-bold m-auto p-3'>
                      <img
                        src={require('../../assets/images/icons/flowcharts/' +
                          flowcharts[10].nameImg)}
                        alt=''
                      />
                    </div>
                  </div>
                </div>
                <h3
                  className='absolute top-full left-1/2 -translate-x-1/2 font-bold text-base text-bg-transparent text-center'
                  style={{
                    background: flowcharts[10].color,
                  }}
                >
                  {flowcharts[10].title}
                </h3>
              </div>

              <div className='absolute -top-1/2 -translate-y-full text-sm right-[-54%] -translate-x-1/2'>
                {flowcharts[10].line1}
              </div>
              <div className='absolute top-[130%] text-sm right-[-68%] -translate-x-1/2'>
                {flowcharts[10].line2}
              </div>
            </div>
          </div>
          <div className='h-52 w-full flex justify-center items-center'>
            <div className='h-3 w-full bg-gray-200 relative'>
              <div
                className='absolute h-full w-1/2 left-2/4'
                style={{
                  background: flowcharts[9].color,
                }}
              >
                <div className='absolute -top-[400%] left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center z-[-1]'>
                  <div
                    className='w-[3px] h-4 order-last'
                    style={{
                      background: flowcharts[9].borderColor,
                    }}
                  ></div>
                  <div
                    className='w-22 h-22 border-b-[5px] rounded-b-full'
                    style={{
                      borderColor: flowcharts[9].borderColor,
                    }}
                  >
                    <div className='w-20 h-20 rounded-full flex items-center justify-center text-white font-bold m-auto p-3'>
                      <img
                        src={require('../../assets/images/icons/flowcharts/' +
                          flowcharts[9].nameImg)}
                        alt=''
                      />
                    </div>
                  </div>
                </div>
                <h3
                  className='absolute top-full left-1/2 -translate-x-1/2 font-bold text-base text-bg-transparent text-center'
                  style={{
                    background: flowcharts[9].color,
                  }}
                >
                  {flowcharts[9].title}
                </h3>
              </div>
            </div>
          </div>
          <div className='h-52 w-full flex justify-start items-start'>
            <div className='h-[calc(50%+0.375rem)] w-1/2 bg-transition border-b-[.75rem] border-r-[.75rem] border-gray-200 rounded-br-full'></div>
          </div>
        </div>
      </div>
    </>
  );
}
