import { Link } from 'react-router-dom';

export default function CardProduct({
  item,
  button = true,
  path = 'product',
  setIsOpenModal = false,
  setSelectedProduct,
}) {
  const pathName = (path, item) => {
    return path === 'product'
      ? `/${path}/${item.path}`
      : `/${path}/${item.path}/page/1`;
  };

  const handleClick = (e) => {
    sessionStorage.setItem('scrollPosition', window.scrollY);
  };

  return (
    <>
      <div
        key={item._id}
        className='max-w-sm mx-auto bg-white border border-gray-200 shadow w-full flex flex-col h-full'
      >
        <Link to={pathName(path, item)} onClick={handleClick} className='block'>
          {/* className='max-h-[254px] w-full object-cover' */}
          <img
            className='w-full object-cover'
            src={item.thumbnail || item.gallery?.[0]}
            alt=''
          />
        </Link>
        <div className='p-3 sm:p-5 h-full flex flex-col justify-between'>
          <div>
            <Link to={pathName(path, item)} onClick={handleClick}>
              <h5
                className='text-lg md:text-lg font-bold tracking-tight text-gray-900 hover:text-emerald-500 truncate'
                title={item.name}
              >
                {item.name}
              </h5>
            </Link>
            {/* <p
              className='mb-3 font-normal text-gray-700 text-sm md:text-base  md:h-12 h-10 truncate max-w-sm'
              dangerouslySetInnerHTML={{ __html: item.description }}
            ></p> */}
            <p
              className='mt-3 font-normal text-gray-700 text-sm md:text-base md:min-h-[1rem] min-h-[2.5rem] max-w-sm'
              dangerouslySetInnerHTML={{ __html: item.description }}
            ></p>
          </div>
          {button && (
            <div className='flex items-end'>
              <button
                className='mt-3 sm:inline-flex hidden items-center px-4 py-3 text-sm font-medium text-center text-white bg-emerald-500 rounded-full hover:bg-emerald-600 focus:ring-4 focus:outline-none focus:ring-emerald-300'
                onClick={() => {
                  setIsOpenModal(true);
                  setSelectedProduct(item);
                }}
              >
                Request Quotation
                <svg
                  aria-hidden='true'
                  className='w-4 h-4 ml-2 -mr-1'
                  fill='currentColor'
                  viewBox='0 0 20 20'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fillRule='evenodd'
                    d='M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z'
                    clipRule='evenodd'
                  ></path>
                </svg>
              </button>
              <Link
                to={`/order/${item.path}`}
                className='inline-flex sm:hidden items-center px-4 py-3 text-sm font-medium text-center text-white bg-emerald-500 rounded-full hover:bg-emerald-600 focus:ring-4 focus:outline-none focus:ring-emerald-300'
              >
                Request Quotation
                <svg
                  aria-hidden='true'
                  className='w-4 h-4 ml-2 -mr-1'
                  fill='currentColor'
                  viewBox='0 0 20 20'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fillRule='evenodd'
                    d='M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z'
                    clipRule='evenodd'
                  ></path>
                </svg>
              </Link>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
