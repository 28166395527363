import { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { seo } from '../assets/json/seo';

export default function NotFound() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>
            {seo.notFound.title}
          </title>
          <meta name='keywords' content={seo.notFound.keywords} />
          <meta name='description' content={seo.notFound.description} />
        </Helmet>
      </HelmetProvider>
      <div className='container flex flex-wrap items-start my-8'>
        <div className='sm:w-3/12'>
          <span className='text-[6em] font-bold opacity-30'>404</span>
        </div>
        <div className='sm:w-9/12'>
          <h1 className='text-3xl font-medium mb-6'>
            Oops! That page can’t be found.
          </h1>
          <p className='mb-6 opacity-70'>
            It looks like nothing was found at this location. Maybe try one of
            the links below or a search?
          </p>
          <Link
            to={'/'}
            type='button'
            className='py-2 px-6 bg-gray-200 rounded hover:bg-emerald-500 hover:text-white transition duration-500'
          >
            Go Home{' '}
          </Link>
        </div>
      </div>
    </>
  );
}
