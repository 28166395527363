import { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { seo } from '../assets/json/seo';
import Main from '../components/quality-control/Main';

export default function QualityControl() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>
            {seo.qualityControl.title}
          </title>
          <meta name='keywords' content={seo.qualityControl.keywords} />
          <meta name='description' content={seo.qualityControl.description} />
        </Helmet>
      </HelmetProvider>
      <Main />
    </>
  )
}
