import { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useLocation, useOutletContext, useParams } from 'react-router';
import { seo } from '../assets/json/seo';
// import { toast } from 'react-toastify';
import Main from '../components/shop/MainShop';

import { search } from '../services/product';

const initBreadcrumb = [
  {
    name: 'Products',
  },
];

export default function Shop() {
  const { categories, materials } = useOutletContext();

  const [data, setData] = useState();
  const [dataBody, setDataBody] = useState({
    page: 1,
    pageSize: 8,
  });

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    search(dataBody).then(res => setData(res));
  }, [dataBody]);

  const { page, path } = useParams();
  
  useEffect(() => {
    setData();
    setDataBody({
      ...dataBody,
      path,
      page,
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, path]);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>
            {seo.products.title}
          </title>
          <meta name='keywords' content={seo.products.keywords} />
          <meta name='description' content={seo.products.description} />
        </Helmet>
      </HelmetProvider>
      <Main
        initBreadcrumb={initBreadcrumb}
        data={data}
        button={true}
        categories={categories}
        materials={materials}
        dataBody={dataBody}
        setDataBody={setDataBody}
      />
    </>
  );
}
