import { customers } from '../../assets/json/about';
import styles from './css/about.module.css';

export default function Customer() {
  return (
    <>
      {/* <div className='w-full md:w-10/12 lg:w-[900px] mx-auto'> */}
      <div className='w-full mx-auto mt-24'>
        <h2 className='uppercase text-gray-500 font-bold tracking-wide text-center pb-8 text-4xl'>
          MEETING WITH OUR CUSTOMERS
        </h2>

        <div className={`grid grid-cols-1 md:grid-cols-2 gap-4`}>
          {customers.map((img, index) => (
            <img
              className={styles.img}
              key={index}
              src={require('../../assets/images/about/' + img)}
              alt={index}
            />
          ))}
        </div>
      </div>
    </>
  );
}
