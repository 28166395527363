import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';
import { sendOrder } from '../../../services/email';
import { orderValidator } from '../../../utils/validation';

import 'overlayscrollbars/overlayscrollbars.css';
import ThankToast from '../Toasts/ThankToast';

export default function OrderModal({ open, setClose, selectedProduct }) {
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [validationMessages, setValidationMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [openToast, setOpenToast] = useState(false);

  const handleSend = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const dataPost = {
      name,
      email,
      phone_number: phoneNumber,
      product: {
        link: window.location.origin + '/product/' + selectedProduct?.path,
        name: selectedProduct?.name || "",
      },
      subject,
      message,
    };

    const arr = [];
    const validator = orderValidator(dataPost);
    if (validator.error) {
      validator.error.details.forEach((item) =>
        arr.push({ key: item.context.key, message: item.message })
      );
      setValidationMessages(arr);
      setIsLoading(false);
    } else {
      sendOrder(dataPost)
        .then((res) => {
          setOpenToast(true);
          setClose(false);
          setIsLoading(false);
        })
        .catch((err) => {
          toast.error('An error occurred. Please try again!');
          setIsLoading(false);
        });
    }
  };

  const handleInputChange = (key) => {
    const messages = JSON.parse(JSON.stringify(validationMessages)).filter(
      (item) => item.key !== key
    );

    setValidationMessages(messages);
  };

  useEffect(() => {
    if (open) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'auto';
  }, [open]);

  return (
    <>
      <ThankToast openToast={openToast} setOpenToast={setOpenToast} />
      {/* Main modal */}
      <div
        id='staticModal'
        data-modal-backdrop='static'
        tabIndex='-1'
        aria-hidden='true'
        className={
          'fixed top-0 right-0 z-[200] w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-full transition bg-black/40 hidden sm:block ' +
          (open ? '!left-0 opacity-100' : '!-left-[9999px] opacity-0')
        }
      >
        <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full max-w-2xl md:h-auto'>
          {/* Modal content */}
          <div className='relative bg-white rounded-lg shadow'>
            {/* Modal header */}
            <div className='flex items-start justify-between p-4 border-b rounded-t'>
              <h3 className='text-xl font-semibold text-gray-900'>
                {selectedProduct ? (
                  selectedProduct.name
                ) : (
                  <Skeleton height={25} width={250} />
                )}
              </h3>
              <button
                type='button'
                className='text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center'
                data-modal-hide='staticModal'
                onClick={() => setClose(false)}
              >
                <svg
                  className='w-5 h-5'
                  fill='currentColor'
                  viewBox='0 0 20 20'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fillRule='evenodd'
                    d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                    clipRule='evenodd'
                  ></path>
                </svg>
              </button>
            </div>
            {/* Modal body */}
            <div className='mt-6 px-6 max-h-[80vh] overflow-hidden'>
              <OverlayScrollbarsComponent
                className='max-h-[80vh]'
                // options={{ scrollbars: { autoHide: true } }}
                defer
              >
                <form className='space-y-6 h-full' onSubmit={handleSend}>
                  {/* name */}
                  <div className=''>
                    <label
                      htmlFor='full-name'
                      className='block mb-2 text-sm font-medium text-gray-900'
                    >
                      Your Name{' '}
                      <span className='text-red-500 font-bold'>*</span>
                    </label>
                    <input
                      type='text'
                      name='full-name'
                      id='full-name'
                      className={
                        'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ' +
                        (validationMessages.find(
                          (message) => message.key === 'name'
                        )
                          ? 'border-red-600'
                          : 'border-gray-300')
                      }
                      placeholder='Your Name'
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      onInput={() => handleInputChange('name')}
                    />
                    <small className='text-red-500 font-medium'>
                      {validationMessages.map((message) =>
                        message.key === 'name' ? message.message : null
                      )}
                    </small>
                  </div>
                  {/* phone */}
                  <div className=''>
                    <label
                      htmlFor='email'
                      className='block mb-2 text-sm font-medium text-gray-900'
                    >
                      Your Phone Number{' '}
                      <span className='text-red-500 font-bold'>*</span>
                    </label>
                    <input
                      type='text'
                      name='phone_number'
                      id='phone_number'
                      className={
                        'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ' +
                        (validationMessages.find(
                          (message) => message.key === 'phone_number'
                        )
                          ? 'border-red-600'
                          : 'border-gray-300')
                      }
                      placeholder='Your Phone Number'
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      onInput={() => handleInputChange('phone_number')}
                    />
                    <small className='text-red-500 font-medium'>
                      {validationMessages.map((message) =>
                        message.key === 'phone_number' ? message.message : null
                      )}
                    </small>
                  </div>
                  {/* email */}
                  <div className=''>
                    <label
                      htmlFor='email'
                      className='block mb-2 text-sm font-medium text-gray-900'
                    >
                      Your Email{' '}
                      <span className='text-red-500 font-bold'>*</span>
                    </label>
                    <input
                      type='email'
                      name='email'
                      id='email'
                      className={
                        'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ' +
                        (validationMessages.find(
                          (message) => message.key === 'email'
                        )
                          ? 'border-red-600'
                          : 'border-gray-300')
                      }
                      placeholder='example@email.com'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      onInput={() => handleInputChange('email')}
                    />
                    <small className='text-red-500 font-medium'>
                      {validationMessages.map((message) =>
                        message.key === 'email' ? message.message : null
                      )}
                    </small>
                  </div>
                  {/* subject */}
                  <div className=''>
                    <label
                      htmlFor='subject'
                      className='block mb-2 text-sm font-medium text-gray-900'
                    >
                      Subject <span className='text-red-500 font-bold'>*</span>
                    </label>
                    <input
                      type='text'
                      name='subject'
                      id='subject'
                      className={
                        'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ' +
                        (validationMessages.find(
                          (message) => message.key === 'subject'
                        )
                          ? 'border-red-600'
                          : 'border-gray-300')
                      }
                      placeholder='Subject'
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                      onInput={() => handleInputChange('subject')}
                    />
                    <small className='text-red-500 font-medium'>
                      {validationMessages.map((message) =>
                        message.key === 'subject' ? message.message : null
                      )}
                    </small>
                  </div>
                  {/* message */}
                  <div className=''>
                    <label
                      htmlFor='message'
                      className='block mb-2 text-sm font-medium text-gray-900'
                    >
                      Message <span className='text-red-500 font-bold'>*</span>
                    </label>
                    <textarea
                      type='text'
                      name='message'
                      id='message'
                      className={
                        'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ' +
                        (validationMessages.find(
                          (message) => message.key === 'message'
                        )
                          ? 'border-red-600'
                          : 'border-gray-300')
                      }
                      placeholder='Your Content'
                      rows={4}
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      onInput={() => handleInputChange('message')}
                    ></textarea>
                    <small className='text-red-500 font-medium'>
                      {validationMessages.map((message) =>
                        message.key === 'message' ? message.message : null
                      )}
                    </small>
                  </div>
                  {/* Modal footer */}
                  <div className='flex items-center py-6 space-x-2 border-t border-gray-200 rounded-b'>
                    {!isLoading ? (
                      <button
                        data-modal-hide='staticModal'
                        type='submit'
                        className='text-white bg-emerald-700 hover:bg-emerald-800 focus:ring-4 focus:outline-none focus:ring-emerald-300 font-medium rounded-full text-sm px-5 py-2.5 text-center'
                      >
                        Send
                      </button>
                    ) : (
                      <button
                        data-modal-hide='staticModal'
                        type='button'
                        className='text-white bg-emerald-700 focus:outline-none font-medium rounded-full text-sm px-5 py-2.5 text-center cursor-default'
                      >
                        <svg
                          className='animate-spin h-5 w-5 text-white'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 24 24'
                        >
                          <circle
                            className='opacity-25'
                            cx='12'
                            cy='12'
                            r='10'
                            stroke='currentColor'
                            strokeWidth='4'
                          ></circle>
                          <path
                            className='opacity-75'
                            fill='currentColor'
                            d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                          ></path>
                        </svg>
                      </button>
                    )}
                    <button
                      data-modal-hide='staticModal'
                      type='button'
                      className='text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-full border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 '
                      onClick={() => setClose(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </OverlayScrollbarsComponent>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
