import { title } from '../../assets/json/quality-control';
import FlowDescription from './FlowDescription';

export default function Main() {
  return (
    <>
      <h1 className='text-3xl text-gray-500 font-bold text-center pt-6 container uppercase'>
        {title}
      </h1>
      <FlowDescription />
    </>
  );
}
