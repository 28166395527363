import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
// import { toast } from 'react-toastify';
import { searchFeaturedProducts } from '../../../services/product';
import CardProduct from '../../shared/cards/CardProduct';
import OrderModal from '../../shared/modal/OrderModal';

export default function FeaturedProductSession() {
  const [featuredProducts, setFeaturedProducts] = useState();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();

  useEffect(() => {
    searchFeaturedProducts({
      page: 1,
      pageSize: 24,
    })
      .then((res) => setFeaturedProducts(res))
      // .catch((err) => toast.error('Error while loading products!'));
  }, []);

  return (
    <>
      <OrderModal
        open={isOpenModal}
        setClose={setIsOpenModal}
        selectedProduct={selectedProduct}
      />
      <span
        id='new'
        className='h-[75px] -mt-[75px] md:h-[165px] md:-mt-[165px] invisible block'
      ></span>
      <div className='container min-h-[200px] bg-gray-50 !py-8'>
        <div className=' mx-auto'>
          {featuredProducts ? (
            <h2 className='uppercase text-4xl text-gray-500 font-bold tracking-wide text-center pb-8'>
              FEATURED Products
            </h2>
          ) : (
            <Skeleton containerClassName='pb-4 block' height={45} />
          )}
          <div className='grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 sm:gap-8 gap-4'>
            {featuredProducts
              ? featuredProducts?.map((item) => (
                  <CardProduct
                    key={item._id}
                    item={item}
                    button={true}
                    path='product'
                    setIsOpenModal={setIsOpenModal}
                    setSelectedProduct={setSelectedProduct}
                  />
                ))
              : [...Array(4)].map((_, index) => (
                  <Skeleton
                    key={index}
                    containerClassName='max-w-sm mx-auto border border-gray-200 rounded-lg shadow w-full block h-[300px]'
                    className='!block w-full h-full'
                  />
                ))}
          </div>
        </div>
      </div>
    </>
  );
}
