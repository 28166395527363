import HeaderSearch from './HeaderSearch';
import Navigator from './Navigator';

export default function HeaderBottom({
  headerBottomRef,
  categories,
  materials,
  updates,
}) {
  return (
    <>
      <div
        ref={headerBottomRef}
        className='hidden md:flex items-center sticky top-0 bg-white z-[100]'
      >
        <div className='flex items-center justify-between px-[15px] w-full'>
          {/* Left - Navigator */}
          <Navigator
            categories={categories}
            updates={updates}
            materials={materials}
          />
          {/* Right */}
          <HeaderSearch />
        </div>
      </div>
    </>
  );
}
