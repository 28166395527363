import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import Breadcrumb from '../shared/breadcrumb/Breadcrumb';
import FilterButton from '../shared/buttons/FilterButton';
import CardProduct from '../shared/cards/CardProduct';
import Empty from '../shared/empty/Empty';
import OrderModal from '../shared/modal/OrderModal';
import SloganProduct from '../shared/other/SloganProduct';
import NewPagination from '../shared/pagination/NewPagination';
// import Pagination from '../shared/pagination/Pagination';
import Filter from './Filter';
import FilterMobile from './FilterMobile';

const sortOptions = [
  {
    title: 'Sort by default',
    value: '',
  },
  {
    title: 'Sort by newest',
    value: 'newest',
  },
  {
    title: 'Sort by A-Z',
    value: 'az',
  },
  {
    title: 'Sort by Z-A',
    value: 'za',
  },
];

export default function Main({
  initBreadcrumb,
  data,
  button = true,
  categories,
  materials,
  dataBody,
  setDataBody,
}) {
  // const [currentItems, setCurrentItems] = useState();
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();

  const handleSort = (e) => { 
    setDataBody({
      ...dataBody,
      sort: e.target.value,
    })
  }

  return (
    <>
      <OrderModal
        open={isOpenModal}
        setClose={setIsOpenModal}
        selectedProduct={selectedProduct}
      />
      <FilterMobile
        isOpenDrawer={isOpenDrawer}
        setIsOpenDrawer={setIsOpenDrawer}
        categories={categories}
        materials={materials}
      />
      <FilterButton setIsOpenDrawer={setIsOpenDrawer} />
      <div className='min-h-[300px] mb-8'>
        <div className='bg-slate-100'>
          <div className='min-h-[60px] py-3 flex justify-between items-center md:flex-row flex-col container'>
            <div className='mb-4 md:mb-0'>
              <Breadcrumb breadcrumbs={initBreadcrumb} />
            </div>
            <button
              className='flex md:hidden gap-2 uppercase font-semibold hover:text-emerald-500 mb-4 md:mb-0'
              onClick={() => setIsOpenDrawer(true)}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth={1.5}
                stroke='currentColor'
                className='w-6 h-6'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75'
                />
              </svg>{' '}
              Filter
            </button>
            <div>
              <select
                className='py-2 px-4 border border-slate-500 rounded outline-none focus:outline-emerald-500'
                defaultValue={''}
                // value={valueSort}
                onChange={handleSort}
              >
                {sortOptions.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.title}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className='flex flex-wrap my-6 container items-start'>
          <Filter categories={categories} materials={materials} />
          {data ? (
            data.data?.length > 0 ? (
              <div
                className={
                  'w-full md:w-10/12 grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 grid md:border-l md:pl-6'
                }
              >
                {data.data.map((item) => (
                  <CardProduct
                    key={item._id}
                    item={item}
                    button={button}
                    path='product'
                    setIsOpenModal={setIsOpenModal}
                    setSelectedProduct={setSelectedProduct}
                  />
                ))}
              </div>
            ) : (
              <div className='w-full md:w-10/12 '>
                <Empty />
              </div>
            )
          ) : (
            <div
              className={
                'w-full md:w-10/12 grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 grid'
              }
            >
              {[...Array(8)].map((_, index) => (
                <Skeleton
                  key={index}
                  containerClassName='max-w-sm mx-auto border border-gray-200 rounded-lg shadow w-full block h-[300px]'
                  className='!block w-full h-full'
                />
              ))}
            </div>
          )}
        </div>

        <div className='mx-auto w-full text-center py-8'>
          <NewPagination total={data?.total || 0} />
        </div>

        <SloganProduct />
      </div>
    </>
  );
}
