import { useEffect, useState } from 'react';
import Drawer from 'react-modern-drawer';
import { Link, useLocation } from 'react-router-dom';

export default function FilterMobile({
  isOpenDrawer,
  setIsOpenDrawer,
  data,
  title,
}) {
  const location = useLocation();

  const [size, setSize] = useState('0%');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 376) setSize('100%');
      else if (window.innerWidth < 567) setSize('70%');
      else setSize('50%');
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (window.innerWidth < 376) setSize('100%');
    else if (window.innerWidth < 567) setSize('70%');
    else setSize('30%');
  }, []);

  const scrollTop = () => window.scrollTo(0, 0);

  return (
    <>
      <Drawer
        open={isOpenDrawer}
        onClose={() => setIsOpenDrawer(false)}
        direction='right'
        size={size}
        className='!min-h-screen !h-full'
      >
        <div className='py-8 min-h-screen flex flex-col justify-center mt-4'>
          <div className='self-start p-4 relative w-full'>
            <div
              className='absolute top-2 right-0 p-2 cursor-pointer hover:rotate-90 transition duration-500 hover:text-emerald-500'
              onClick={() => setIsOpenDrawer(false)}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth={1.5}
                stroke='currentColor'
                className='w-6 h-6'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M6 18L18 6M6 6l12 12'
                />
              </svg>
            </div>
            <h2 className='uppercase font-bold text-gray-800 text-lg'>
              {title}
            </h2>
            <div className='w-8 h-1 bg-slate-300 rounded-lg my-2'></div>
            <ul>
              {data &&
                data.map((item) => (
                  <li key={item._id} onClick={() => setIsOpenDrawer(false)}>
                    <Link
                      to={`/${title}/${item.path}/page/1`}
                      onClick={scrollTop}
                      className={
                        'py-2 inline-block font-medium hover:font-bold outline-none ' +
                        (location.pathname.indexOf(item.path) >= 0
                          ? '!font-bold text-emerald-500'
                          : ' hover:text-gray-800')
                      }
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </Drawer>
    </>
  );
}
