
export default function ButtonBottom({ linkTo }) {
  return (
    <>
      <a
        href={linkTo}
        className=' my-2 mx-auto transition w-[40px] h-[40px] bg-translate text-black rounded-full flex justify-center items-center button-bottom'
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth={1.5}
          stroke='currentColor'
          className='w-6 h-6'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M19.5 8.25l-7.5 7.5-7.5-7.5'
          />
        </svg>
      </a>
    </>
  );
}
