

export default function Empty() {
  return (
    <>
      <div className='w-full flex flex-col justify-center items-center'>
        <img className='w-[400px] h-[400px] object-cover' src={require('../../../assets/images/other/empty.png')} alt='' />
        <h2 className='font-semibold text-2xl mt-4 text-black/60'>There is no products</h2>
      </div>
    </>
  )
}
