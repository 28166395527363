export const listBadges = [
  {
    title: 'GLOBE EXPORT',
    nameFile: '1.png',
    color:
      'linear-gradient(90deg, #1fb483 35%, #e9e668 60%)',
  },
  {
    title: 'VARIOUS PRODUCTS',
    nameFile: '2.png',
    color:
      'linear-gradient(0deg, #7a0cab 35%, #e852c0 60%)',
  },
  {
    title: 'VERY REASONABLE PRICE',
    nameFile: '3.png',
    color:
      'linear-gradient(90deg, #fcd553 50%, #fbb507 100%)',
  },
  {
    title: 'STRONG CUSTOMIZATION',
    nameFile: '4.png',
    color:
      'linear-gradient(135deg, rgba(58,213,252,1) 40%, rgba(208,100,232,1) 100%)',
  },
  {
    title: 'SYSTEMATICAL QUALITY CONTROL',
    nameFile: '5.png',
    color:
      'linear-gradient(225deg, rgba(236,78,71,1) 40%, rgba(251,193,15,1) 100%)',
  },
  {
    title: 'NATURAL MATERIALS',
    nameFile: '6.png',
    color:
      'linear-gradient(90deg, #9ebd54 40%, #658934 100%)',
  },
];