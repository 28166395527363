export const video = {
  src: `https://www.youtube.com/embed/HGaI0mjjlcc?si=WU8ST2VMypSQIbj4`,
  caption: {
    content: `We understand how tough competition in market is, consumers' needs change every day that requires manufacturers constantly follow and update 
    their techniques. Beside updating our techniques, we also update how we do business. Therefore, satisfying all customers is our plan. 
    When you cooperating with us, both your price and quality requirements are satisfied. You need products with low prices and good quality or 
    need the ones with very high prices and premium quality, HandiPassion serve this for you well. All we do is for customers' satisfaction. 
     <br /> `,
    button: {
      title: `ABOUT US`,
      to: `/about-us`,
    }
  }
}