import { flowcharts } from '../../assets/json/howwemake';

export default function MainFlowMobile() {
  return (
    <>
      <div className='min-h-screen w-full max-w-[375px] px-4 mx-auto lg:hidden'>
        <div className='items-center flex flex-wrap'>
          {/* 1 */}
          <div className='h-52 w-2/12 flex justify-end items-center'>
            <div className='h-3 w-full bg-gray-200'></div>
          </div>
          <div className='h-52 w-8/12 flex justify-end items-center'>
            <div className='h-3 w-full bg-gray-200 relative'>
              <div
                className='absolute h-full w-[85px] left-[-30px]'
                // style={{
                //   background: flowcharts[0].color,
                // }}
              >
                <div className='absolute -top-[0%] left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center z-[1]'>
                  <div
                    className='w-[3px] h-4 order-last'
                    style={{
                      background: flowcharts[0].borderColor,
                    }}
                  ></div>
                  <div
                    className='w-18 h-18 border-b-[5px] rounded-b-full'
                    style={{ borderColor: flowcharts[0].borderColor }}
                  >
                    <div className='w-16 h-16 rounded-full flex items-center justify-center text-white font-bold m-auto p-3 bg-white'>
                      <img
                        src={require('../../assets/images/icons/flowcharts/' +
                          flowcharts[0].nameImg)}
                        alt=''
                      />
                    </div>
                  </div>
                </div>
                <h3
                  className='absolute bottom-[-620%] left-1/2 -translate-x-1/2 font-bold text-sm text-bg-transparent text-center'
                  style={{
                    background: flowcharts[0].color,
                  }}
                >
                  {flowcharts[0].title}
                </h3>
              </div>
              <div>
                <div className='absolute bottom-[100%] mb-1 text-xs left-[50%] -translate-x-1/2'>
                  {flowcharts[0].line1}
                </div>
                <div className='absolute top-[100%] mt-1 text-xs left-[50%] -translate-x-1/2 whitespace-nowrap'>
                  {flowcharts[0].line2}
                </div>
              </div>
              <div
                className='absolute h-full w-[85px] right-[-30px]'
                // style={{
                //   background: flowcharts[0].color,
                // }}
              >
                <div className='absolute -top-[0%] left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center z-[1]'>
                  <div
                    className='w-[3px] h-4 order-last'
                    style={{
                      background: flowcharts[1].borderColor,
                    }}
                  ></div>
                  <div
                    className='w-18 h-18 border-b-[5px] rounded-b-full'
                    style={{ borderColor: flowcharts[1].borderColor }}
                  >
                    <div className='w-16 h-16 rounded-full flex items-center justify-center text-white font-bold m-auto p-3 bg-white'>
                      <img
                        src={require('../../assets/images/icons/flowcharts/' +
                          flowcharts[1].nameImg)}
                        alt=''
                      />
                    </div>
                  </div>
                </div>
                <h3
                  className='absolute bottom-[-620%] left-1/2 -translate-x-1/2 font-bold text-sm text-bg-transparent text-center'
                  style={{
                    background: flowcharts[1].color,
                  }}
                >
                  {flowcharts[1].title}
                </h3>
              </div>
            </div>
          </div>
          <div className='h-52 w-2/12 flex justify-start items-end'>
            <div className='h-[calc(50%+0.375rem)] w-full bg-transition border-t-[.75rem] border-r-[.75rem] border-gray-200 rounded-tr-full relative'>
              <div className='absolute rotate-90 text-center left-[-7px] text-xs bottom-[-7px] whitespace-nowrap'>
                {flowcharts[1].line1}
              </div>
              <div className='absolute rotate-90 left-[12px] bottom-[-6px] text-xs whitespace-nowrap'>
                {flowcharts[1].line2}
              </div>
            </div>
          </div>

          {/* 2 */}
          <div className='h-52 w-2/12 flex justify-end items-end'>
            <div className='h-[calc(50%+0.375rem)] w-full bg-transition border-t-[.75rem] border-l-[.75rem] border-gray-200 rounded-tl-full relative'>
              <div className='absolute rotate-90 bottom-[-8px] left-[-35px] text-xs whitespace-nowrap'>
                {flowcharts[3].line1}
              </div>
              <div className='absolute rotate-90 left-[-42px] text-xs text-center bottom-[-10px] whitespace-nowrap'>
                {flowcharts[3].line2}
              </div>
            </div>
          </div>
          <div className='h-52 w-8/12 flex justify-end items-center'>
            <div className='h-3 w-full bg-gray-200 relative'>
              <div
                className='absolute h-full w-[85px] left-[-30px]'
                // style={{
                //   background: flowcharts[3].color,
                // }}
              >
                <div className='absolute -top-[0%] left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center z-[1]'>
                  <div
                    className='w-[3px] h-4 order-last'
                    style={{
                      background: flowcharts[3].borderColor,
                    }}
                  ></div>
                  <div
                    className='w-18 h-18 border-b-[5px] rounded-b-full'
                    style={{ borderColor: flowcharts[3].borderColor }}
                  >
                    <div className='w-16 h-16 rounded-full flex items-center justify-center text-white font-bold m-auto p-3 bg-white'>
                      <img
                        src={require('../../assets/images/icons/flowcharts/' +
                          flowcharts[3].nameImg)}
                        alt=''
                      />
                    </div>
                  </div>
                </div>
                <h3
                  className='absolute bottom-[-450%] left-1/2 -translate-x-1/2 font-bold text-sm text-bg-transparent text-center'
                  style={{
                    background: flowcharts[3].color,
                  }}
                >
                  {flowcharts[3].title}
                </h3>
              </div>
              <div>
                <div className='absolute bottom-[100%] mb-1 text-xs left-[50%] -translate-x-1/2'>
                  {flowcharts[2].line1}
                </div>
                <div className='absolute top-[100%] mt-1 text-xs left-[50%] -translate-x-1/2 whitespace-nowrap'>
                  {flowcharts[2].line2}
                </div>
              </div>
              <div
                className='absolute h-full w-[85px] right-[-30px]'
                // style={{
                //   background: flowcharts[2].color,
                // }}
              >
                <div className='absolute -top-[0%] left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center z-[1]'>
                  <div
                    className='w-[3px] h-4 order-last'
                    style={{
                      background: flowcharts[2].borderColor,
                    }}
                  ></div>
                  <div
                    className='w-18 h-18 border-b-[5px] rounded-b-full'
                    style={{ borderColor: flowcharts[2].borderColor }}
                  >
                    <div className='w-16 h-16 rounded-full flex items-center justify-center text-white font-bold m-auto p-3 bg-white'>
                      <img
                        src={require('../../assets/images/icons/flowcharts/' +
                          flowcharts[2].nameImg)}
                        alt=''
                      />
                    </div>
                  </div>
                </div>
                <h3
                  className='absolute bottom-[-450%] left-1/2 -translate-x-1/2 font-bold text-sm text-bg-transparent text-center'
                  style={{
                    background: flowcharts[2].color,
                  }}
                >
                  {flowcharts[2].title}
                </h3>
              </div>
            </div>
          </div>
          <div className='h-52 w-2/12 flex justify-start items-start'>
            <div className='h-[calc(50%+0.375rem)] w-full bg-transition border-b-[.75rem] border-r-[.75rem] border-gray-200 rounded-br-full'></div>
          </div>

          {/* 3 */}
          <div className='h-52 w-2/12 flex justify-end items-start'>
            <div className='h-[calc(50%+0.375rem)] w-full bg-transition border-b-[.75rem] border-l-[.75rem] border-gray-200 rounded-bl-full'></div>
          </div>
          <div className='h-52 w-8/12 flex justify-end items-center'>
            <div className='h-3 w-full bg-gray-200 relative'>
              <div
                className='absolute h-full w-[85px] left-[-30px]'
                // style={{
                //   background: flowcharts[4].color,
                // }}
              >
                <div className='absolute -top-[0%] left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center z-[1]'>
                  <div
                    className='w-[3px] h-4 order-last'
                    style={{
                      background: flowcharts[4].borderColor,
                    }}
                  ></div>
                  <div
                    className='w-18 h-18 border-b-[5px] rounded-b-full'
                    style={{ borderColor: flowcharts[4].borderColor }}
                  >
                    <div className='w-16 h-16 rounded-full flex items-center justify-center text-white font-bold m-auto p-3 bg-white'>
                      <img
                        src={require('../../assets/images/icons/flowcharts/' +
                          flowcharts[4].nameImg)}
                        alt=''
                      />
                    </div>
                  </div>
                </div>
                <h3
                  className='absolute bottom-[-450%] left-1/2 -translate-x-1/2 font-bold text-sm text-bg-transparent text-center'
                  style={{
                    background: flowcharts[4].color,
                  }}
                >
                  {flowcharts[4].title}
                </h3>
              </div>
              <div>
                <div className='absolute bottom-[100%] mb-1 text-xs left-[50%] -translate-x-1/2'>
                  {flowcharts[4].line1}
                </div>
                <div className='absolute top-[100%] mt-1 text-xs left-[50%] -translate-x-1/2 whitespace-nowrap'>
                  {flowcharts[4].line2}
                </div>
              </div>
              <div
                className='absolute h-full w-[85px] right-[-30px]'
                // style={{
                //   background: flowcharts[5].color,
                // }}
              >
                <div className='absolute -top-[0%] left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center z-[1]'>
                  <div
                    className='w-[3px] h-4 order-last'
                    style={{
                      background: flowcharts[5].borderColor,
                    }}
                  ></div>
                  <div
                    className='w-18 h-18 border-b-[5px] rounded-b-full'
                    style={{ borderColor: flowcharts[5].borderColor }}
                  >
                    <div className='w-16 h-16 rounded-full flex items-center justify-center text-white font-bold m-auto p-3 bg-white'>
                      <img
                        src={require('../../assets/images/icons/flowcharts/' +
                          flowcharts[5].nameImg)}
                        alt=''
                      />
                    </div>
                  </div>
                </div>
                <h3
                  className='absolute bottom-[-450%] left-1/2 -translate-x-1/2 font-bold text-sm text-bg-transparent text-center'
                  style={{
                    background: flowcharts[5].color,
                  }}
                >
                  {flowcharts[5].title}
                </h3>
              </div>
            </div>
          </div>
          <div className='h-52 w-2/12 flex justify-start items-end'>
            <div className='h-[calc(50%+0.375rem)] w-full bg-transition border-t-[.75rem] border-r-[.75rem] border-gray-200 rounded-tr-full relative'>
              <div className='absolute rotate-90 text-center left-[20px] text-xs bottom-[-10px] whitespace-nowrap'>
                {flowcharts[5].line1}
              </div>
              <div className='absolute rotate-90 left-[15px] bottom-[-6px] text-xs whitespace-nowrap'>
                {flowcharts[5].line2}
              </div>
            </div>
          </div>

          {/* 4 */}
          <div className='h-52 w-2/12 flex justify-end items-end'>
            <div className='h-[calc(50%+0.375rem)] w-full bg-transition border-t-[.75rem] border-l-[.75rem] border-gray-200 rounded-tl-full relative'>
              <div className='absolute rotate-90 bottom-[-8px] left-[-35px] text-xs whitespace-nowrap'>
                {flowcharts[7].line1}
              </div>
              <div className='absolute rotate-90 left-[-40px] text-xs text-center bottom-[-10px] whitespace-nowrap'>
                {flowcharts[7].line2}
              </div>
            </div>
          </div>
          <div className='h-52 w-8/12 flex justify-end items-center'>
            <div className='h-3 w-full bg-gray-200 relative'>
              <div
                className='absolute h-full w-[85px] left-[-30px]'
                // style={{
                //   background: flowcharts[7].color,
                // }}
              >
                <div className='absolute -top-[0%] left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center z-[1]'>
                  <div
                    className='w-[3px] h-4 order-last'
                    style={{
                      background: flowcharts[7].borderColor,
                    }}
                  ></div>
                  <div
                    className='w-18 h-18 border-b-[5px] rounded-b-full'
                    style={{ borderColor: flowcharts[7].borderColor }}
                  >
                    <div className='w-16 h-16 rounded-full flex items-center justify-center text-white font-bold m-auto p-3 bg-white'>
                      <img
                        src={require('../../assets/images/icons/flowcharts/' +
                          flowcharts[7].nameImg)}
                        alt=''
                      />
                    </div>
                  </div>
                </div>
                <h3
                  className='absolute bottom-[-450%] left-1/2 -translate-x-1/2 font-bold text-sm text-bg-transparent text-center'
                  style={{
                    background: flowcharts[7].color,
                  }}
                >
                  {flowcharts[7].title}
                </h3>
              </div>
              <div>
                <div className='absolute bottom-[100%] mb-1 text-xs left-[50%] -translate-x-1/2'>
                  {flowcharts[6].line1}
                </div>
                <div className='absolute top-[100%] mt-1 text-xs left-[50%] -translate-x-1/2 whitespace-nowrap'>
                  {flowcharts[6].line2}
                </div>
              </div>
              <div
                className='absolute h-full w-[85px] right-[-30px]'
                // style={{
                //   background: flowcharts[6].color,
                // }}
              >
                <div className='absolute -top-[0%] left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center z-[1]'>
                  <div
                    className='w-[3px] h-4 order-last'
                    style={{
                      background: flowcharts[6].borderColor,
                    }}
                  ></div>
                  <div
                    className='w-18 h-18 border-b-[5px] rounded-b-full'
                    style={{ borderColor: flowcharts[6].borderColor }}
                  >
                    <div className='w-16 h-16 rounded-full flex items-center justify-center text-white font-bold m-auto p-3 bg-white'>
                      <img
                        src={require('../../assets/images/icons/flowcharts/' +
                          flowcharts[6].nameImg)}
                        alt=''
                      />
                    </div>
                  </div>
                </div>
                <h3
                  className='absolute bottom-[-450%] left-1/2 -translate-x-1/2 font-bold text-sm text-bg-transparent text-center'
                  style={{
                    background: flowcharts[6].color,
                  }}
                >
                  {flowcharts[6].title}
                </h3>
              </div>
            </div>
          </div>
          <div className='h-52 w-2/12 flex justify-start items-start'>
            <div className='h-[calc(50%+0.375rem)] w-full bg-transition border-b-[.75rem] border-r-[.75rem] border-gray-200 rounded-br-full'></div>
          </div>

          {/* 5 */}
          <div className='h-52 w-2/12 flex justify-end items-start'>
            <div className='h-[calc(50%+0.375rem)] w-full bg-transition border-b-[.75rem] border-l-[.75rem] border-gray-200 rounded-bl-full'></div>
          </div>
          <div className='h-52 w-8/12 flex justify-end items-center'>
            <div className='h-3 w-full bg-gray-200 relative'>
              <div
                className='absolute h-full w-[85px] left-[-30px]'
                // style={{
                //   background: flowcharts[8].color,
                // }}
              >
                <div className='absolute -top-[0%] left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center z-[1]'>
                  <div
                    className='w-[3px] h-4 order-last'
                    style={{
                      background: flowcharts[8].borderColor,
                    }}
                  ></div>
                  <div
                    className='w-18 h-18 border-b-[5px] rounded-b-full'
                    style={{ borderColor: flowcharts[8].borderColor }}
                  >
                    <div className='w-16 h-16 rounded-full flex items-center justify-center text-white font-bold m-auto p-3 bg-white'>
                      <img
                        src={require('../../assets/images/icons/flowcharts/' +
                          flowcharts[8].nameImg)}
                        alt=''
                      />
                    </div>
                  </div>
                </div>
                <h3
                  className='absolute bottom-[-450%] left-1/2 -translate-x-1/2 font-bold text-sm text-bg-transparent text-center'
                  style={{
                    background: flowcharts[8].color,
                  }}
                >
                  {flowcharts[8].title}
                </h3>
              </div>
              <div>
                <div className='absolute bottom-[100%] mb-1 text-xs left-[50%] -translate-x-1/2 whitespace-nowrap'>
                  {flowcharts[8].line1}
                </div>
                <div className='absolute top-[100%] mt-1 text-xs left-[50%] -translate-x-1/2 whitespace-nowrap'>
                  {flowcharts[8].line2}
                </div>
              </div>
              <div
                className='absolute h-full w-[85px] right-[-30px]'
                // style={{
                //   background: flowcharts[9].color,
                // }}
              >
                <div className='absolute -top-[0%] left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center z-[1]'>
                  <div
                    className='w-[3px] h-4 order-last'
                    style={{
                      background: flowcharts[9].borderColor,
                    }}
                  ></div>
                  <div
                    className='w-18 h-18 border-b-[5px] rounded-b-full'
                    style={{ borderColor: flowcharts[9].borderColor }}
                  >
                    <div className='w-16 h-16 rounded-full flex items-center justify-center text-white font-bold m-auto p-3 bg-white'>
                      <img
                        src={require('../../assets/images/icons/flowcharts/' +
                          flowcharts[9].nameImg)}
                        alt=''
                      />
                    </div>
                  </div>
                </div>
                <h3
                  className='absolute bottom-[-450%] left-1/2 -translate-x-1/2 font-bold text-sm text-bg-transparent text-center'
                  style={{
                    background: flowcharts[9].color,
                  }}
                >
                  {flowcharts[9].title}
                </h3>
              </div>
            </div>
          </div>
          <div className='h-52 w-2/12 flex justify-start items-end'>
            <div className='h-[calc(50%+0.375rem)] w-full bg-transition border-t-[.75rem] border-r-[.75rem] border-gray-200 rounded-tr-full relative'>
              <div className='absolute rotate-90 text-center left-[12px] text-xs bottom-[-10px] whitespace-nowrap'>
                {flowcharts[9].line1}
              </div>
              <div className='absolute rotate-90 left-[-4px] bottom-[-6px] text-xs whitespace-nowrap'>
                {flowcharts[9].line2}
              </div>
            </div>
          </div>

          {/* 4 */}
          <div className='h-52 w-9/12 flex justify-end items-end'></div>
          <div className='h-52 w-1/12 flex justify-end items-center'>
            <div className='h-3 w-full bg-gray-200 relative'>
              <div
                className='absolute h-full w-[85px] right-[-30px]'
                // style={{
                //   background: flowcharts[10].color,
                // }}
              >
                <div className='absolute -top-[0%] left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center z-[1]'>
                  <div
                    className='w-[3px] h-4 order-last'
                    style={{
                      background: flowcharts[10].borderColor,
                    }}
                  ></div>
                  <div
                    className='w-18 h-18 border-b-[5px] rounded-b-full'
                    style={{ borderColor: flowcharts[10].borderColor }}
                  >
                    <div className='w-16 h-16 rounded-full flex items-center justify-center text-white font-bold m-auto p-3 bg-white'>
                      <img
                        src={require('../../assets/images/icons/flowcharts/' +
                          flowcharts[10].nameImg)}
                        alt=''
                      />
                    </div>
                  </div>
                </div>
                <h3
                  className='absolute bottom-[-620%] left-1/2 -translate-x-1/2 font-bold text-sm text-bg-transparent text-center'
                  style={{
                    background: flowcharts[10].color,
                  }}
                >
                  {flowcharts[10].title}
                </h3>
              </div>
            </div>
          </div>
          <div className='h-52 w-2/12 flex justify-start items-start'>
            <div className='h-[calc(50%+0.375rem)] w-full bg-transition border-b-[.75rem] border-r-[.75rem] border-gray-200 rounded-br-full'></div>
          </div>
        </div>
      </div>
    </>
  );
}
