export const why = {
  title: `why choose us ?`,
  whys: [
    {
      title: `Very Reasonable Price`,
      nameFile: `why-1.png`,
      content:
        `Effectively optimize production & cost to give the best prices to customers, always competitive.`,
    },
    {
      title: `High-Quality Product`,
      nameFile: `why-2.png`,
      content:
        `We deeply understand product quality every customer requires and how we need to do to control quality well.`,
    },
    {
      title: `Reliability`,
      nameFile: `why-3.png`,
      content:
        `We don't want to sell to customers once, we do want to sell to the ones many times`,
    },
  ],
};
