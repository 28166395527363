import { useState } from 'react';
import { Link } from 'react-router-dom';
import { info } from '../../../assets/json/info';
import { emailValidator } from '../../../utils/validation';
import { sendRegisterMail } from '../../../services/email';
import { toast } from 'react-toastify';
import ThankToast from '../Toasts/ThankToast';

export default function Footer() {
  const [validationMessages, setValidationMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [openToast, setOpenToast] = useState(false);

  const handleSend = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const dataPost = {
      email,
    };

    const arr = [];
    const validator = emailValidator(dataPost);
    if (validator.error) {
      validator.error.details.forEach((item) =>
        arr.push({ key: item.context.key, message: item.message })
      );
      setValidationMessages(arr);
      setIsLoading(false);
    } else {
      sendRegisterMail(dataPost)
        .then((res) => {
          setOpenToast(true);
          setIsLoading(false);
        })
        .catch((err) => {
          toast.error('An error occurred. Please try again!');
          setIsLoading(false);
        });
    }
  };

  const handleInputChange = (key) => {
    const messages = JSON.parse(JSON.stringify(validationMessages)).filter(
      (item) => item.key !== key
    );

    setValidationMessages(messages);
  };

  return (
    <>
      <ThankToast openToast={openToast} setOpenToast={setOpenToast} />
      <div className=' bg-[#252525]'>
        <div className='container border-t border-gray-100 !py-8'>
          <div className='mx-auto'>
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8'>
              <div>
                <div className='text-center leading-none mb-4'>
                  <Link to={'/'} className='text-center'>
                    <img
                      className='max-h-[64px]'
                      src={require('../../../assets/images/logo/logo_white.png')}
                      alt=''
                    />
                  </Link>
                </div>
                <div className='mb-4 text-gray-400'>
                  <div dangerouslySetInnerHTML={{ __html: info.info }}></div>
                </div>
                <div className='text-gray-400'>
                  <ul className='flex items-center w-full justify-start'>
                    <span className='text-medium mr-2 leading-none'>
                      Follow us
                    </span>
                    {info.socials &&
                      info.socials
                        .sort((a, b) => a.order - b.order)
                        .map((item, index) => (
                          <li
                            key={index}
                            className='pl-2 pr-2 last:pr-0 hover:text-emerald-500'
                          >
                            <a href={item.url} target='_blank' rel='noreferrer'>
                              <span
                                dangerouslySetInnerHTML={{ __html: item.icon }}
                              ></span>
                            </a>
                          </li>
                        ))}
                  </ul>
                </div>
              </div>
              <div className='mt-9 '>
                <h3 className='font-bold text-lg mb-4 text-slate-50'>
                  Address & Contact
                </h3>
                <ul className='text-gray-400'>
                  <li className='mb-2 flex items-center gap-2'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth={1.5}
                      stroke='currentColor'
                      className='w-5 h-5 flex-shrink-0'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25'
                      />
                    </svg>
                    <span>{info.address}</span>
                  </li>
                  <li className='mb-2 flex items-center gap-2'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth={1.5}
                      stroke='currentColor'
                      className='w-5 h-5'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z'
                      />
                    </svg>

                    <a
                      className='hover:text-emerald-500'
                      href={'tel:' + info.phone}
                    >
                      {info.phone}
                    </a>
                  </li>
                  <li className='mb-2 flex items-center gap-2'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      strokeWidth={1.5}
                      fill='currentColor'
                      className='bi bi-whatsapp w-5 h-5'
                      viewBox='0 0 16 16'
                    >
                      <path d='M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z' />
                    </svg>

                    <a
                      className='hover:text-emerald-500'
                      href={info?.socials[0]?.url}
                      target='_blank'
                      rel='noreferrer'
                    >
                      +84 86 581 0009
                    </a>
                  </li>
                  <li className='mb-2 flex items-start gap-2'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth={1.5}
                      stroke='currentColor'
                      className='w-5 h-5 mt-[2px]'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75'
                      />
                    </svg>
                    <div>
                      {info.email &&
                        info.email.map((item, index) => (
                          <a
                            className='block hover:text-emerald-500'
                            key={index}
                            href={'mailto:' + item}
                          >
                            {item}
                          </a>
                        ))}
                    </div>
                  </li>
                </ul>
              </div>
              <div className='mt-9'>
                <h3 className='font-bold text-lg mb-4 text-slate-50'>
                  Want to receive product catalogue?
                </h3>
                <label
                  htmlFor='email-address'
                  className='block pb-2  text-gray-400'
                >
                  Your Email Address
                </label>
                <form className='space-y-6' onSubmit={handleSend}>
                  <div className='flex w-full gap-x-2'>
                    <div className='w-full'>
                      <input
                        id='email-address'
                        name='email'
                        type='email'
                        autoComplete='email'
                        className={
                          'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  ' +
                          (validationMessages.find(
                            (message) => message.key === 'email'
                          )
                            ? 'border-red-600'
                            : 'border-gray-300')
                        }
                        placeholder='Your Email Address'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        onInput={() => handleInputChange('email')}
                      />
                    </div>
                    {!isLoading ? (
                      <button
                        data-modal-hide='staticModal'
                        type='submit'
                        className='text-white bg-emerald-500 hover:bg-emerald-600 focus:ring-4 focus:outline-none focus:ring-emerald-300 font-medium rounded-full text-sm px-5 py-2.5 text-center'
                      >
                        Send
                      </button>
                    ) : (
                      <button
                        data-modal-hide='staticModal'
                        type='button'
                        className='text-white bg-emerald-700 focus:outline-none font-medium rounded-full text-sm px-5 py-2.5 text-center cursor-default'
                      >
                        <svg
                          className='animate-spin h-5 w-5 text-white'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 24 24'
                        >
                          <circle
                            className='opacity-25'
                            cx='12'
                            cy='12'
                            r='10'
                            stroke='currentColor'
                            strokeWidth='4'
                          ></circle>
                          <path
                            className='opacity-75'
                            fill='currentColor'
                            d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                          ></path>
                        </svg>
                      </button>
                    )}
                  </div>
                  <small className='text-red-500 font-medium'>
                    {validationMessages.map((message) =>
                      message.key === 'email' ? message.message : null
                    )}
                  </small>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className='container !py-8 border-t text-slate-50'>
          <div className='flex justify-center items-center mx-auto'>
            <div className='hidden sm:block w-1/2 md:w-1/3 md:text-center text-xl font-bold font-about'>
              HandiPassion., JSC
            </div>
            {/* <ul className='flex items-center w-full sm:w-1/2 md:w-1/3 justify-end'>
            <span className='text-medium mr-2 leading-none'>Follow us</span>
            {info.socials &&
              info.socials
                .sort((a, b) => a.order - b.order)
                .map((item, index) => (
                  <li
                    key={index}
                    className='pl-2 pr-2 last:pr-0 hover:text-emerald-500'
                  >
                    <a href={item.url} target='_blank' rel='noreferrer'>
                      <span
                        dangerouslySetInnerHTML={{ __html: item.icon }}
                      ></span>
                    </a>
                  </li>
                ))}
          </ul> */}
          </div>
          <div className='sm:hidden w-full mt-4 pt-2 text-center text-xl font-bold font-about'>
            HandiPassion., JSC
          </div>
        </div>
      </div>
    </>
  );
}
