import { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { seo } from '../assets/json/seo';
import Main from '../components/howwemake/Main';

export default function HowWeMake() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>
            {seo.howWeMake.title}
          </title>
          <meta name='keywords' content={seo.howWeMake.keywords} />
          <meta name='description' content={seo.howWeMake.description} />
        </Helmet>
      </HelmetProvider>
      <Main />
    </>
  )
}
