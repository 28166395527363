export const title = "Quality control";

export const paragraphs = [
  {
    title: `General product check`,
    content: `Firstly, we have to ensure that materials, shape, color, products's accessories, artwork and packagings follow customers' requirements. These are critical elements.`,
    imgName: `1.jpg`,
  },
  {
    title: `Function check`,
    content: `The product has to follow consumers' needs. All functions in actual usage conditions need assuarance. We have to ensure that consumers use products normally.`,
    imgName: `2.jpg`,
  },
  {
    title: `Dimension check`,
    content: `Sizes and weight are within customers' requirements, not over/ below tolerances.`,
    imgName: `3.jpg`,
  },
  {
    title: `Moisture check`,
    content: `It requires from 7 to 12% for the best conditions or follow customers' requirements`,
    imgName: `4.jpg`,
  },
  {
    title: `Barcode or QR check`,
    content: `When barcodes are easily scanned and readable, this proves that barcode scanning devices in supermarkets are able to scan and read.`,
    imgName: `5.jpg`,
  },
  {
    title: `Carton drop test`,
    content: `We follow ISTA 1A or other standards if customers require. This test is simulated to detect damages in actual transportation activities, we will know how damaged products are impacted during transportation.`,
    imgName: `6.jpg`,
  },
  {
    title: `3M adhesive tape test`,
    content: `This test shows how durable printed ink surface is when being peeled off by 3M adhesive tape. The more ink is stick on 3M adhesive tape, the poorer quality printed ink is.`,
    imgName: `7.jpg`,
  },
  {
    title: `Color fastness test`,
    content: `Applied for dyed/ painted products, hangtag, Thank You card and gift box, we will use water or alcohol to test. Good result is that the fabric piece keeps white, no colors appear on it. For other cases, it depends on customers' decisions.`,
    imgName: `8.jpg`,
  },
];
