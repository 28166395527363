import axios from 'axios';

const channelId = 'UCbaeUdpFdYII7udE2aZkIFQ';
const apiKey = 'AIzaSyDvjreWtt_YUSnaAEAdlxyJ2JkxCDjdcVM';

export const getVideos = async ({ pageSize, pageToken }) => {
  const res = await axios.get(
    `https://www.googleapis.com/youtube/v3/search?key=${apiKey}&channelId=${channelId}&part=snippet,id&order=date&maxResults=${pageSize}&pageToken=${
      pageToken ? pageToken : ''
    }`
  );
  return res.data;
};
