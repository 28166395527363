import { visions } from '../../assets/json/about';

export default function Vision() {
  return (
    <>
      {/* <div className='w-full md:w-10/12 lg:w-[900px] mx-auto'> */}
      <div className='w-full mx-auto'>
        <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
          {visions.map((item, index) => (
            <div className='py-2' key={index}>
              <div className='flex flex-col items-start gap-2'>
                <div className='flex '>
                  <div
                    className='w-3 h-auto flex-shrink-0 opacity-80'
                    style={{ backgroundColor: item.color }}
                  ></div>
                  <div className='flex flex-wrap items-center justify-center ml-4'>
                    <img
                      className='w-14 h-14 rounded object-cover '
                      src={require(`../../assets/images/icons/${item.nameImg}`)}
                      alt=''
                    />
                    <div className='font-bold text-left text-3xl uppercase text-back/80 ml-4' style={{ color: item.color}}>
                      {item.title}
                    </div>
                  </div>
                </div>
                {/* <div className='w-1/5 mx-auto my-2 bg-black h-1 rounded-full'></div> */}
                <div className='flex'>
                  <div className='w-3 flex-shrink-0'></div>
                  <div
                    className='text-left text-base text-black font-medium ml-4'
                    style={{ wordSpacing: 'auto' }}
                  >
                    {item.content}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
