export const seo = {
  // Categories Page
  category: {
    title: `Categories | Vietnam Handicraft Factory | Storage Basket`,
    description: `We produce storage/ laundry baskets, storage/serving trays, lampshades/ lanterns, coasters & placemats, wall hanging baskets decor, pet houses and coconut bowls`,
    keywords: `products of handicraft, rattan handicraft products, handicraft products manufacturers, handicraft items manufacturers`,
  },

  // Materials Page
  material: {
    title: `Materials | Vietnam Handicraft Products | Bamboo Products`,
    description: `High-quality products from water hyacinth, seagrass, rattan, bamboo, raffia palm, jute, palm leaf, coconut, areca leaf, corn husk rope and banana bark`,
    keywords: `handicraft wholesalers near me, wholesalers for craft supplies, wholesale handcrafted items, name for handmade craft business`,
  },

  // About Page
  about: {
    title: `About Us | Vietnam Handicraft Wholesalers | Handicraft Factory`,
    description: `Our artisans make high-quality products from water hyacinth, seagrass, rattan, bamboo, raffia palm, jute, palm leaf, corn husk rope and banana bark`,
    keywords: `vietnam handicraft co. ltd, vietnam crafts, handicraft in vietnam, vietnam crafts and arts, vietnam handmade products`,
  },

  // FAQs Page
  faqs: {
    title: `FAQs | Vietnam rattan products| Handicraft Wholesalers`,
    description: `Vietnam handicraft manufacturer and exporter with competitive prices, water hyacinth storage/laundry baskets supplier, woven bamboo products factory in Vietnam`,
    keywords: `handicraft manufacturing factory, craft furniture manufacturer, handicraft export suppliers, handicraft company, handicraft manufacturers in vietnam`,
  },

  // Contact Page
  contact: {
    title: `Contact | Vietnam Handicraft Factory | Vietnamese Handicraft Supplier`,
    description: `Look for a Vietnamese handicraft supplier, Vietnamese handicraft manufacturer, Vietnamese handicraft factory, please contact us now!`,
    keywords: `handicraft wholesalers near me, wholesalers for craft supplies, wholesale handcrafted items, name for handmade craft business`,
  },

  // Home Page
  home: {
    title: `Home | Vietnam Handicraft Manufacturer | Vietnam Handicraft Factory`,
    description: `Vietnam handicraft manufacturer and exporter with competitive prices, water hyacinth storage/laundry baskets supplier, woven bamboo products factory in Vietnam`,
    keywords: `vietnam handicraft factory, handicraft export suppliers, vietnam handmade products, handicraft export company, handicraft factory handicraft`,
  },

  // How we make Page
  howWeMake: {
    title: `How We Make | Handwoven Product Making Processes`,
    description: `Water hyacinth/ seagrass/ bamboo/ rattan wicker storage basket, serving trays, celling lamps, placemats and coasters, wall hanging basket decor, dog/ cat bed (house)`,
    keywords: `manufacturer of handicraft items, handicraft company contact number, wall hanging basket decor, what is handicraft production`,
  },

  // Quality control
  qualityControl: {
    title: `Quality control | Home Decor | Home Storage | Kitchen Storage`,
    description: `Vietnam handicraft manufacturer and exporter with competitive prices, water hyacinth storage/laundry baskets supplier, woven bamboo basket factory in Vietnam`,
    keywords: `vietnam handicraft factory, craft art factory, handmade factory, handicraft factory design, what is handicraft production`,
  },

  // Not found Page
  notFound: {
    title: `Not Founded | Vietnam Handicrafts Wholesalers`,
    description: ``,
    keywords: `vietnam handicraft factory, handicraft export suppliers, vietnam handmade products, handicraft export company, handicraft factory handicraft`,
  },

  // Order Mobile Page
  orderMobile: {
    title: `Order now | Vietnam Handicraft Factory | Vietnamese Handicraft Supplier`,
    description: ``,
    keywords: `vietnam handicraft factory, handicraft export suppliers, vietnam handmade products, handicraft export company, handicraft factory handicraft`,
  },

  // Products by Category Page
  productByCategory: {
    title: `| Vietnam Handicrafts Manufacturers`,
    description: ``,
    keywords: `vietnam handicraft factory, handicraft export suppliers, vietnam handmade products, handicraft export company, handicraft factory handicraft`,
  },

  // Products by Material Page
  productByMaterial: {
    title: `| Home Storage Manufacturers | Home Decor Factory`,
    description: ``,
    keywords: `water hyacinth weed, seagrass sedge, rattan willow, bamboo, raffia palm, jute, palm leaf, corn husk rope, paper rope, banana bark`,
  },

  // Products Page
  products: {
    title: `Vietnam Home Decor Supplier | Vietnam Home Decor Factory`,
    description: ``,
    keywords: `manufacturer of handicraft items, handicraft bags manufacturer, craft furniture manufacturer, handicraft export suppliers`,
  },

  // items for trading Page
  trading: {
    title: `Vietnam Rattan Furniture | Vietnam Home Decor Factory`,
    description: ``,
    keywords: `vietnam handicraft factory, handicraft export suppliers, vietnam handmade products, handicraft export company, handicraft factory handicraft`,
  },

  // Product Detail Page
  product: {
    // product = name + title
    title: ``,
    description: ``,
    keywords: `handicraft manufacturing factory, craft furniture manufacturer, handicraft export suppliers, handicraft company`,
  },

  // Product Video Page
  productVideos: {
    title: ``,
    description: ``,
    keywords: ``,
  }
}
