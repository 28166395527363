import { useEffect, useState } from 'react';
import Lightbox from 'yet-another-react-lightbox';
import {
  Fullscreen,
  Slideshow,
  Thumbnails
} from 'yet-another-react-lightbox/plugins';

import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

export default function LightBox({ data, openLightBox, setOpenLightBox, selectedImg }) {
  const [listSrc, setListSrc] = useState([]);

  useEffect(() => {
    if (data) {
      const list = [];
      data.gallery.forEach((item) =>
        list.push({
          src: item,
          srcSet: data.gallery,
        })
      );

      setListSrc(list);
    }
  }, [data]);

  useEffect(() => {
    if (listSrc && selectedImg) {
      listSrc.sort(function (x, y) {
        return x.src === selectedImg ? -1 : y.src === selectedImg ? 1 : 0;
      });
    }
  }, [listSrc, selectedImg]);

  return (
    <>
      <Lightbox
        open={openLightBox}
        close={() => setOpenLightBox(false)}
        slides={listSrc}
        plugins={[Fullscreen, Slideshow, Thumbnails]}
      />
    </>
  )
}
