import { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useLocation, useParams } from 'react-router';
import { search as searchCategories } from '../services/category';
import Main from '../components/category/MainCategory';
import { seo } from '../assets/json/seo';

export default function Category() {
  const [data, setData] = useState();
  const [dataBody, setDataBody] = useState({
    page: 1,
    pageSize: 8,
  });

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    searchCategories(dataBody).then(res => setData(res));
  }, [dataBody]);

  const { page } = useParams();
  
  useEffect(() => {
    setDataBody({
      ...dataBody,
      page,
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);
  
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>
            {seo.category.title}
          </title>
          <meta name='keywords' content={seo.category.keywords} />
          <meta name='description' content={seo.category.description} />
        </Helmet>
      </HelmetProvider>
      <Main data={data} />
    </>
  );
}
