export const howwemake = {
  title: `Basic production processes`,
}

export const flowcharts = [
  {
    title: `Raw Materials`,
    nameImg: `1.png`,
    color: `linear-gradient(270deg, rgba(11,178,255,1) 40%, rgba(55,125,255,1) 79%)`,
    borderColor: `rgb(11,178,255)`,
    line1: `IPC performed`,
    line2: `ISO 2589-1 – 2.5/ 4.0`,
  },
  {
    title: `Materials Processing`,
    nameImg: `2.png`,
    color: `linear-gradient(270deg, rgba(39,240,156,1) 40%, rgba(17,168,247,1) 79%)`,
    borderColor: `rgb(39,240,156)`,
    line1: `IPC performed`,
    line2: `ISO 2589-1 – 2.5/ 4.0`,
  },
  {
    title: `Shaping`,
    nameImg: `3.png`,
    color: `linear-gradient(90deg, rgba(132,211,255,1) 40%, rgba(229,142,252,1) 79%)`,
    borderColor: `rgb(132,211,255)`,
    line1: `DUPRO performed`,
    line2: `100%`,
  },
  {
    title: `Weaving`,
    nameImg: `4.png`,
    color: `linear-gradient(90deg, rgba(165,234,244,1) 40%, rgba(184,221,126,1) 79%)`,
    borderColor: `rgb(165,234,244)`,
    line1: `100%`,
    line2: `DUPRO performed`,
  },
  {
    title: `Cleaning`,
    nameImg: `5.png`,
    color: `linear-gradient(270deg, rgba(11,178,255,1) 40%, rgba(55,125,255,1) 79%)`,
    borderColor: `rgb(11,178,255)`,
    line1: `DUPRO performed`,
    line2: `100%`,
  },
  {
    title: `Gluing`,
    nameImg: `11.jpg`,
    color: `linear-gradient(180deg, rgba(253,203,18,1) 0%, rgba(233,76,59,1) 72%)`,
    borderColor: `rgb(253,203,18)`,
    line1: `100%`,
    line2: `DUPRO performed`,
  },
  {
    title: `Drying`,
    nameImg: `6.png`,
    color: `linear-gradient(90deg, rgba(115,204,242,1) 40%, rgba(252,217,53,1) 79%)`,
    borderColor: `rgb(115,204,242)`,
    line1: `100%`,
    line2: `DUPRO performed`,
  },
  {
    title: `Finishing`,
    nameImg: `7.png`,
    color: `linear-gradient(90deg, rgba(0,238,145,1) 40%, rgba(0,170,237,1) 79%)`,
    borderColor: `rgb(0,238,145)`,
    line1: `100%`,
    line2: `DUPRO performed`,
  },
  {
    title: `Packing`,
    nameImg: `8.png`,
    color: `linear-gradient(90deg, rgba(64,179,205,1) 40%, rgba(56,203,218,1) 79%)`,
    borderColor: `rgb(64,179,205)`,
    line1: `100%`,
    line2: `DUPRO performed`,
  },
  {
    title: `Warehouse`,
    nameImg: `9.png`,
    color: `linear-gradient(90deg, rgba(69,237,244,1) 40%, rgba(10,116,241,1) 79%)`,
    borderColor: `rgb(69,237,244)`,
    line1: `ISO 2589-1 – 2.5/ 4.0`,
    line2: `PSI performed`,
  },
  {
    title: `Exporting`,
    nameImg: `10.png`,
    color: `linear-gradient(90deg, rgba(77,192,167,1) 40%, rgba(77,170,190,1) 79%)`,
    borderColor: `rgb(77,192,167)`,
    line1: `PSI performed`,
    line2: `ISO 2859-1 - 2.5/ 4.0`,
  },
];

export const paragraphs = [
  {
    title: `Raw Materials`,
    content: `Raw materials must be strictly selected to ensure finished products are high-quality. We use sampling standard ISO 2589-1 with Level GII: 2.5/ 4.0 performed by our QC inspectors to control quality, we are aware that being good at first steps leads to being good at final results.`,
    imgName: `1.jpg`
  },
  {
    title: `Materials Processing`,
    content: `After being carefully inspected, raw materials will be processed for next steps. Every material has its special processing procedure that we strictly follow to avoid bad products in the future. We randomly check again to make sure that materials are ready for the shaping process.`,
    imgName: `2.jpg`,
  },
  {
    title: `Shaping`,
    content: `This process is very important for products because it decides basic characteristic of product such as shape, size and appearance. Once products are manufactured with incorrect basic characteristics, customers will not receive goods that causes bad reputation as well as profit damage for manufacturers. Therefore, we perform 100% inspection to sort out unqualified pieces.`,
    imgName: `3.jpg`,
  },
  {
    title: `Weaving `,
    content: `If we consider shaping process as a skeleton, the weaving process will be like a skin surface for product.  Delicacy from product is shown in this process which requires dexterity of weavers’ hands, in other words, weaving which brings soul for handicraft products shows particular characteristics in every region and from every weaver’s hands. HandiPassion performs 100% inspection for this process.`,
    imgName: `4.jpg`,
  },
  {
    title: `Cleaning`,
    content: `After the weaving process finishes, semi-products will be cleaned up to remove dirt, stain and dust due to transferring semi-products from steps to steps, they need cleaning up for the gluing process.`,
    imgName: `5.jpg`,
  },
  {
    title: `Gluing`,
    content: `Products are dipped into glue mixture to be easily formed. Glue will help products remain final formation and more beautiful.`,
    imgName: `6.jpg`,
  },
  {
    title: `Drying`,
    content: `The drying process seems normal but it is the most decisive step in handicraft manufacture. When drying, we have to strictly control dryness level in drying machine. Time and moisture must be continuously checked to ensure they aren’t allowed to exceed standard. Semi-products processed aren’t too dry or too moist, being too moist causes mildew, being too dry causes brittle parts making semi-products easily broken and deformed. Therefore, 100% inspection must be performed in the drying process.`,
    imgName: `7.jpg`,
  },
  {
    title: `Finishing`,
    content: `Through many processes, products need finishing by cutting excess parts, removing dirt or stain, checking product conformity or reworking non-conformed products to gain completeness before being packed. After this process, all products must be conformed in accordance with customers’ requirements as well as HandiPassion’s requirements. 100% inspection is performed to finally confirm 100% products are within requirement.`,
    imgName: `8.jpg`,
  },
  {
    title: `Packing`,
    content: `Finished products are packed in polybag, inner box or gift box then put into carton. Customers have their particular packing requirements that we strictly follow. All packing information (shipping mark, artwork, barcode) must be checked 100%.`,
    imgName: `9.jpg`,
  },
  {
    title: `Warehouse`,
    content: `We store goods in confidential warehouses which reach good conditions to avoid damage from weather, animal as well as loss from human.`,
    imgName: `10.jpg`,
  },
  {
    title: `Exporting`,
    content: `Before shipment from 3-7 days, we perform Pre-shipment Inspection based on ISO 2589-1 – Level GII: 2.5/ 4.0. This Pre-shipment Inspection helps us check conformity of product at the last time so that we are sure that products are within requirements without any issues. Finally, we perform Container Loading Check for quantity assurance.`,
    imgName: `11.jpg`,
  },
]