import { useState } from 'react';
import { toast } from 'react-toastify';
import { info } from '../../assets/json/info';
import { sendContact } from '../../services/email';
import { contactValidator } from '../../utils/validation';
import ThankToast from '../shared/Toasts/ThankToast';

export default function Main() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [content, setContent] = useState('');
  const [validationMessages, setValidationMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [openToast, setOpenToast] = useState(false);

  const handleSend = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const dataPost = {
      name,
      email,
      phone,
      content,
    };

    const arr = [];
    const validator = contactValidator(dataPost);
    if (validator.error) {
      validator.error.details.forEach((item) =>
        arr.push({ key: item.context.key, message: item.message })
      );
      setValidationMessages(arr);
      setIsLoading(false);
    } else {
      sendContact(dataPost)
        .then((res) => {
          setOpenToast(true);
          setIsLoading(false);
        })
        .catch((err) => {
          toast.error('An error occurred. Please try again!');
          setIsLoading(false);
        });
    }
  };

  const handleInputChange = (key) => {
    const messages = JSON.parse(JSON.stringify(validationMessages)).filter(
      (item) => item.key !== key
    );

    setValidationMessages(messages);
  };

  return (
    <>
      <ThankToast openToast={openToast} setOpenToast={setOpenToast} />
      <div className='container'>
        <div className='min-h-[200px] max-w-xl mx-auto px-8 bg-slate-100'>
          <h1 className='py-6 text-3xl text-center text-gray-500 font-bold uppercase'>
            Contact
          </h1>
          <ul className='py-4 border-y  '>
            <li className='mb-4 flex items-center gap-2'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth={1.5}
                stroke='currentColor'
                className='w-5 h-5 flex-shrink-0'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25'
                />
              </svg>
              <span>{info.address}</span>
            </li>
            <li className='mb-4 flex items-center gap-2'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth={1.5}
                stroke='currentColor'
                className='w-5 h-5'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z'
                />
              </svg>

              <a className='hover:text-emerald-500' href={'tel:' + info.phone}>
                {info.phone}
              </a>
            </li>
            <li className='mb-2 flex items-center gap-2'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                strokeWidth={1.5}
                fill='currentColor'
                className='bi bi-whatsapp w-5 h-5'
                viewBox='0 0 17 17'
              >
                <path d='M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z' />
              </svg>

              <a
                className='hover:text-emerald-500'
                href={info?.socials[0]?.url}
                target='_blank'
                rel='noreferrer'
              >
                +84 86 581 0009
              </a>
            </li>
            <li className='mb-2 flex items-start gap-2'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth={1.5}
                stroke='currentColor'
                className='w-5 h-5 mt-[2px]'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75'
                />
              </svg>
              <div>
                {info.email &&
                  info.email.map((item, index) => (
                    <a
                      className='block hover:text-emerald-500'
                      key={index}
                      href={'mailto:' + item}
                    >
                      {item}
                    </a>
                  ))}
              </div>
            </li>
          </ul>
          <div className='py-4'>
            <h2 className='capitalize font-semibold text-xl pb-4'>
              Contact us
            </h2>
            <form className='space-y-6' onSubmit={handleSend}>
              {/* name */}
              <div className=''>
                <input
                  type='text'
                  name='full-name'
                  id='full-name'
                  className={
                    'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  ' +
                    (validationMessages.find(
                      (message) => message.key === 'name'
                    )
                      ? 'border-red-600'
                      : 'border-gray-300')
                  }
                  placeholder='Your Name *'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  onInput={() => handleInputChange('name')}
                />
                <small className='text-red-500 font-medium'>
                  {validationMessages.map((message) =>
                    message.key === 'name' ? message.message : null
                  )}
                </small>
              </div>
              {/* phone */}
              <div className=''>
                <input
                  type='text'
                  name='phone'
                  id='phone'
                  className={
                    'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  ' +
                    (validationMessages.find(
                      (message) => message.key === 'phone'
                    )
                      ? 'border-red-600'
                      : 'border-gray-300')
                  }
                  placeholder='Your Phone *'
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  onInput={() => handleInputChange('phone')}
                />
                <small className='text-red-500 font-medium'>
                  {validationMessages.map((message) =>
                    message.key === 'phone' ? message.message : null
                  )}
                </small>
              </div>
              {/* email */}
              <div className=''>
                <input
                  type='email'
                  name='email'
                  id='email'
                  className={
                    'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  ' +
                    (validationMessages.find(
                      (message) => message.key === 'email'
                    )
                      ? 'border-red-600'
                      : 'border-gray-300')
                  }
                  placeholder='Your Email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onInput={() => handleInputChange('email')}
                />
                <small className='text-red-500 font-medium'>
                  {validationMessages.map((message) =>
                    message.key === 'email' ? message.message : null
                  )}
                </small>
              </div>
              {/* content */}
              <div className=''>
                <textarea
                  type='text'
                  name='content'
                  id='content'
                  className={
                    'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  ' +
                    (validationMessages.find(
                      (message) => message.key === 'content'
                    )
                      ? 'border-red-600'
                      : 'border-gray-300')
                  }
                  placeholder='Your Content *'
                  rows={4}
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                  onInput={() => handleInputChange('content')}
                ></textarea>
                <small className='text-red-500 font-medium'>
                  {validationMessages.map((message) =>
                    message.key === 'content' ? message.message : null
                  )}
                </small>
              </div>

              <div className='text-end'>
                {!isLoading ? (
                  <button
                    data-modal-hide='staticModal'
                    type='submit'
                    className='text-white bg-emerald-700 hover:bg-emerald-800 focus:ring-4 focus:outline-none focus:ring-emerald-300 font-medium rounded-full text-sm px-5 py-2.5 text-center'
                  >
                    Send
                  </button>
                ) : (
                  <button
                    data-modal-hide='staticModal'
                    type='button'
                    className='text-white bg-emerald-700 focus:outline-none font-medium rounded-full text-sm px-5 py-2.5 text-center cursor-default'
                  >
                    <svg
                      className='animate-spin h-5 w-5 text-white'
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                    >
                      <circle
                        className='opacity-25'
                        cx='12'
                        cy='12'
                        r='10'
                        stroke='currentColor'
                        strokeWidth='4'
                      ></circle>
                      <path
                        className='opacity-75'
                        fill='currentColor'
                        d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                      ></path>
                    </svg>
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
