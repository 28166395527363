import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router';
import TopButton from '../components/shared/buttons/TopButton';
import Footer from '../components/shared/footer/Footer';
import Header from '../components/shared/header/Header';
import Toast from '../components/shared/Toasts/Toast';
import { search as searchCategories } from '../services/category';
import { search as searchMaterials } from '../services/material';
import { search as searchCustomers } from '../services/customer';
import FilterButton from '../components/shared/buttons/FilterButton';
import DrawerNav from '../components/shared/header/DrawerNav';
// import { search as searchUpdates } from '../services/update';

export default function Layout() {
  const [categories, setCategories] = useState([]);
  const [materials, setMaterials] = useState();
  const [customers, setCustomers] = useState();
  const [updates] = useState();

  useEffect(() => {
    (async () => {
      const cates = await searchCategories({
        page: 1,
        pageSize: 1000,
      });
      setCategories(cates.data);
    })();
  }, []);

  // Search services
  useEffect(() => {
    searchCustomers({
      name: '',
    }).then((res) => setCustomers(res));
  }, []);

  useEffect(() => {
    (async () => {
      await searchMaterials({
        page: 1,
        pageSize: 1000,
      }).then((res) => setMaterials(res.data));
      // .catch((err) => toast.error('Error while loading materials!'));
    })();
  }, []);

  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const location = useLocation();

  return (
    <>
      <Header categories={categories} updates={updates} materials={materials} />
      <Outlet context={{ categories, updates, materials, customers }} />
      <Footer />
      <TopButton />
      <Toast />

      {!(
        location.pathname.indexOf('/categories') > -1 ||
        location.pathname.indexOf('/materials') > -1 ||
        location.pathname.indexOf('/products') > -1
      ) ? (
        <>
          <FilterButton setIsOpenDrawer={setIsOpenDrawer} />
          <DrawerNav
            isOpenDrawer={isOpenDrawer}
            setIsOpenDrawer={setIsOpenDrawer}
            categories={categories}
            materials={materials}
            number={2}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
}

// const CustomToastWithLink = () => (
//   <div>
//     11/02 12:00: Updated, view{' '}
//     <Link to='/timeline' className='text-emerald-500 font-bold underline'>
//       here
//     </Link>
//     .
//   </div>
// );

// toast.info(CustomToastWithLink, {
//   autoClose: 60000,
//   icon: '🚀',
// });
