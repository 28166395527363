import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link, useLocation } from 'react-router-dom';

import { navs } from '../../../assets/json/navigate';

export default function Navigator({ categories, materials, updates }) {
  const location = useLocation();

  const [pathname, setPathname] = useState('');
  const [navigates, setNavigates] = useState(navs);

  useEffect(() => {
    if (location) setPathname(location.pathname || '');
  }, [location]);

  useEffect(() => {
    const arr = JSON.parse(JSON.stringify(navigates));
    arr.forEach((item) => {
      switch (item.id) {
        case 'category':
          item.dropdown = categories || [];
          break;
        case 'material':
          item.dropdown = materials || [];
          break;
        default:
          break;
      }
    });

    setNavigates(arr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories, materials]);
  
  return (
    <>
      <div className='max-h-full'>
        <ul className='flex'>
          <li className='text-[.8rem] mx-3 first:ml-0 relative group'>
            <Link
              to={'/'}
              className={
                'uppercase leading-[43px] py-2 tracking-wide transition-all flex items-center font-bold group-hover:text-emerald-500' +
                (pathname === '/' ? ' text-emerald-500' : ' text-gray-500')
              }
            >
              Home
            </Link>
          </li>
          {navigates &&
            navigates.map((item, index) => (
              <li
                key={index}
                className='text-[.8rem] mx-3 first:ml-0 relative group'
              >
                <Link
                  to={item.url}
                  className={
                    'uppercase leading-[43px] py-2 tracking-wide transition-all flex items-center font-bold group-hover:text-emerald-500' +
                    (pathname.indexOf(item.url) >= 0
                      ? ' text-emerald-500'
                      : ' text-gray-500')
                  }
                >
                  {item.title}{' '}
                  {item.isDropdown && (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth='1.5'
                      stroke='currentColor'
                      className='w-4 h-4 ml-2'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M19.5 8.25l-7.5 7.5-7.5-7.5'
                      />
                    </svg>
                  )}
                </Link>
                {item.isDropdown && (
                  <ul
                    className={
                      'py-4 px-8 min-w-[260px] absolute transition shadow border rounded z-[9] -left-[9999px] group-hover:left-0 top-full bg-white group-hover:opacity-100 opacity-0 duration-500'
                    }
                  >
                    {item.dropdown ? (
                      item?.dropdown?.map((drop, i) => (
                        <li key={i} className='py-2 last:border-none border-b'>
                          <Link
                            to={`${
                              item.id === 'material'
                                ? 'materials'
                                : 'categories'
                            }/${drop.path}/page/1`}
                            className={
                              'font-bold hover:text-emerald-500 transition ' +
                              (pathname.indexOf(drop.path) >= 0
                                ? ' text-emerald-500'
                                : ' text-gray-500')
                            }
                          >
                            {drop.name}
                          </Link>
                        </li>
                      ))
                    ) : (
                      <Skeleton height={20} />
                    )}
                  </ul>
                )}
              </li>
            ))}
        </ul>
      </div>
    </>
  );
}
