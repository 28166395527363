import { howwemake } from '../../assets/json/howwemake';
import FlowDescription from './FlowDescription';
import MainFlow from './MainFlow';
import MainFlowMobile from './MainFlowMobile';


export default function Main() {
  return (
    <>
      <h1 className='text-3xl text-gray-500 font-bold text-center pt-6 container uppercase'>{howwemake.title}</h1>
      <MainFlow />
      <MainFlowMobile />
      <FlowDescription />
    </>
  )
}
