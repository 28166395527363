import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Swiper, SwiperSlide } from 'swiper/react';

// import required modules
import { FreeMode, Thumbs } from 'swiper';

// import './styles/style.css';

export default function GalleryDetail({
  data,
  setSelectedImg,
  setOpenLightBox,
  selectedImg,
}) {
  const [thumbsSwiper, setThumbsSwiper] = useState();

  return (
    <>
      <div className='flex items-center flex-wrap md:flex-nowrap md:w-5/12 md:pr-6 md:gap-12 gap-8 md:border-r'>
        <div className='relative order-1 md:order-2 max-w-[calc(100vw-4rem)] swiper swiper-container'>
          <div className='absolute z-2 top-0 left-0 mt-5 ml-5'>
            <img
              className='w-36 md:w-24 lg:w-36'
              src={require('../../assets/images/logo/logo.png')}
              alt='Handi Passion'
            />
          </div>
          <Swiper
            className='order-1 md:order-2'
            // wrapperClass='max-w-[calc(100vw-4rem)]'
            slidesPerGroup={1}
            slidesPerView={1}
            thumbs={{
              swiper:
                thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
            }}
            modules={[FreeMode, Thumbs]}
          >
            {data ? (
              data?.gallery?.map((item, index) => (
                <SwiperSlide className='rounded min-h-[100px] min-w-[100px] lg:min-w-[280px] xl:min-w-[380px]' key={index}>
                  <div
                    key={index}
                    className='w-full h-full object-center border rounded transition cursor-zoom-in overflow-hidden'
                    onClick={() => {
                      setSelectedImg(item);
                      setOpenLightBox(true);
                    }}
                  >
                    <img
                      className='w-full h-full object-cover rounded'
                      src={item}
                      alt=''
                    />
                  </div>
                </SwiperSlide>
              ))
            ) : (
              <Skeleton className='min-h-[400px] min-w-[400px] block' />
            )}
          </Swiper>
        </div>
        <Swiper
          className='order-2 md:order-1 rounded md:!w-12 shrink-0'
          wrapperClass='flex-row md:flex-col gap-4 md:!w-12'
          onSwiper={setThumbsSwiper}
          slidesPerView={8}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Thumbs]}
          // direction={'vertical'}
        >
          {data
            ? data?.gallery?.map((item, index) => (
                <SwiperSlide className='rounded !h-fit !w-fit' key={index}>
                  <div
                    key={index}
                    className='w-12 h-12 object-cover border shadow rounded hover:scale-110 transition cursor-pointer'
                  >
                    <img
                      className='w-full h-full object-cover rounded'
                      src={item}
                      alt=''
                    />
                  </div>
                </SwiperSlide>
              ))
            : [...Array(3)].map((_, index) => (
                <SwiperSlide key={index} className='!w-fit'>
                  <Skeleton className='!w-12 h-12 block' />
                </SwiperSlide>
              ))}
        </Swiper>
      </div>

      {/* <div className='flex items-center flex-wrap sm:flex-nowrap md:w-1/2 md:pr-6 sm:gap-12 gap-8'>
        <div className='flex sm:flex-col order-2 sm:order-1 w-full sm:w-auto justify-center items-center'>
          {data && data?.gallery ? (
            data.gallery.map((item, index) => (
              <div
                key={index}
                className='w-12 h-12 border shadow mb-2 mr-2 sm:mr-0 rounded hover:scale-110 transition cursor-pointer'
                onClick={() => setSelectedImg(item)}
              >
                <img
                  className='w-full h-full object-cover rounded'
                  src={item}
                  alt=''
                />
              </div>
            ))
          ) : (
            <Skeleton
              containerClassName='block w-12 h-12'
              className='block w-full h-full'
            />
          )}
        </div>
        <div
          className={'hover:cursor-zoom-in rounded w-full sm:order-2 order-1'}
          onClick={() => setOpenLightBox(true)}
        >
          {selectedImg ? (
            <img className='rounded mx-auto shadow' src={selectedImg} alt='' />
          ) : (
            <Skeleton
              containerClassName=''
              className='w-full h-[300px] block p-0 m-0 !leading-[inherit]'
            />
          )}
        </div>
      </div> */}
    </>
  );
}
