import { useState } from 'react';
import { Link } from 'react-router-dom';
import DrawerNav from './DrawerNav';
import SearchForm from './SearchForm';

export default function HeaderTop({
  headerTopRef,
  categories,
  materials,
  updates,
}) {
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [showSearchForm, setShowSearchForm] = useState(false);

  return (
    <>
      <SearchForm
        showSearchForm={showSearchForm}
        setShowSearchForm={setShowSearchForm}
      />
      <div
        ref={headerTopRef}
        className='flex justify-between items-center px-[15px] bg-white sticky top-0 z-[50] md:static'
      >
        {/* Logo */}
        <div
          // id='logo'
          className='order-2 text-center mx-8 leading-none w-[200px] md:w-auto max-w-[200px]'
        >
          <Link to={'/'}>
            <img
              className='max-h-[64px] object-cover mx-auto mt-4 mb-4 md:mb-2'
              src={require('../../../assets/images/logo/logo.png')}
              alt=''
            />
          </Link>
        </div>

        {/* Mobile icon */}
        <div className='order-3 md:order-1 md:hidden md:flex-1 ml-auto md:mr-auto'>
          <button
            className='py-2 hover:text-red-500'
            onClick={() => setIsOpenDrawer(true)}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth={1.5}
              stroke='currentColor'
              className='w-6 h-6'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5'
              />
            </svg>
          </button>
        </div>

        <DrawerNav
          isOpenDrawer={isOpenDrawer}
          setIsOpenDrawer={setIsOpenDrawer}
          categories={categories}
          materials={materials}
        />

        {/* Search icon */}
        <div
          className='order-1 md:order-3 md:hidden md:flex-1 mr-auto md:ml-auto'
          onClick={() => setShowSearchForm(true)}
        >
          <div className='flex justify-end py-2 cursor-pointer hover:text-red-500'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth={1.5}
              stroke='currentColor'
              className='w-5 h-5'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z'
              />
            </svg>
          </div>
        </div>

        {/* Align logo */}
        <div className='order-1 hidden md:block flex-1'></div>
        <div className='order-3 hidden md:block flex-1'></div>
      </div>
    </>
  );
}
