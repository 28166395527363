import { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useLocation, useOutletContext, useParams } from 'react-router';
import { seo } from '../assets/json/seo';
import Main from '../components/search/MainSearch';

import { getByCategory } from '../services/product';

const initBreadcrumb = [
  {
    link: '/categories',
    name: 'Categories',
  },
  {
    name: 'Search',
  },
];

export default function SearchCategory() {
  const { categories } = useOutletContext();

  const [data, setData] = useState();
  const [dataBody, setDataBody] = useState({
    page: 1,
    pageSize: 8,
  });

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (dataBody.path) getByCategory(dataBody).then(res => setData(res));
  }, [dataBody]);

  const { page, path } = useParams();
  
  useEffect(() => {
    setData();
    setDataBody({
      ...dataBody,
      path,
      page,
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, path]);

  const transformCapitalization = (text) => {
    if (text)
    {
      text = text.split('-');
      text = text.reduce((acc, curr) => {
        acc += curr[0].toUpperCase() + curr.substring(1) + ' ';
        return acc;
      }, '');
    }
    return text;
  }

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>
            { transformCapitalization(path) || 'Category' } {seo.productByCategory.title}
          </title>
          <meta name='keywords' content={seo.productByCategory.keywords} />
          <meta name='description' content={seo.productByCategory.description} />
        </Helmet>
      </HelmetProvider>
      <Main
        initBreadcrumb={initBreadcrumb}
        data={data}
        button={true}
        dataSidebar={categories}
        title={'categories'}
        dataBody={dataBody}
        setDataBody={setDataBody}
      />
    </>
  );
}
