import { about, paragraph } from '../../assets/json/about';

export default function Content() {
  return (
    <>
      {/* <div className='w-full md:w-10/12 lg:w-[900px] mx-auto'> */}
      <div className='w-full mx-auto'>
        <h2 className='uppercase text-gray-500 font-bold tracking-wide text-center pb-8 text-4xl'>
          {paragraph.title}
        </h2>
        {/* <div className='w-full md:w-10/12 lg:w-[900px] mx-auto py-[15px] md:grid grid-cols-2 gap-6'> */}

        <div className='flex flex-col md:flex-row w-full mx-auto md:w-10/12 lg:w-[900px] mb-6'>
          <div className='text-center order-2 md:order-1'>
            <img
              className='mx-auto w-full'
              src={require('../../assets/images/about/' + about.nameImg)}
              alt='map'
            />
          </div>
          <div className='w-full mx-auto py-[15px] flex flex-col order-1 md:order-2'>
            {paragraph?.icons?.map((item, index) => (
              <div
                key={index}
                className={
                  'mb-8 last:mb-0 text-center '
                }
              >
                <div
                  className={
                    `overflow-hidden w-[133px] h-[133px] mb-2 mx-auto `
                  }
                >
                  <img
                    className='rounded mb-6 md:mb-0 w-full h-full object-cover'
                    src={require('../../assets/images/about/icons/' +
                      item.nameImg)}
                    alt={item.title}
                  />
                </div>
                <h2
                  className='font-bold text-md mb-2 text-bg-transparent'
                  style={{
                    background: item.color || 'black',
                  }}
                >
                  {item.title}
                </h2>
                <div
                  className='inline-flex font-bold items-end mb-2 text-bg-transparent'
                  style={{
                    background: item.color || 'black',
                  }}
                >
                  <span className='text-3xl leading-none'>{item.number}</span>
                  <span className='text-sm'>{item.smallUnit}</span>
                </div>
                <div
                  className={`text-black font-medium text-bg-transparent`}
                  style={{
                    background: item.color || 'black',
                  }}
                  dangerouslySetInnerHTML={{ __html: item.smallText }}
                ></div>
              </div>
            ))}
          </div>
        </div>

        {/* <div className='w-full mx-auto py-[15px] md:grid grid-cols-2 gap-6'>
          {paragraph?.icons?.map((item, index) => (
            <div
              key={index}
              className={
                'mb-8 last:mb-0 text-center ' +
                (index % 2 === 0 ? 'md:text-left' : 'md:text-right')
              }
            >
              <div
                className={
                  `overflow-hidden w-[150px] h-[150px] mb-4 mx-auto ` +
                  (index % 2 === 0
                    ? 'md:mr-auto md:ml-0'
                    : 'md:ml-auto md:mr-0')
                }
              >
                <img
                  className='rounded mb-6 md:mb-0 w-full h-full object-cover'
                  src={require('../../assets/images/about/icons/' +
                    item.nameImg)}
                  alt={item.title}
                />
              </div>
              <h2
                className='font-bold text-md mb-4 text-bg-transparent'
                style={{
                  background: item.color || 'black',
                }}
              >
                {item.title}
              </h2>
              <div
                className='inline-flex font-bold items-end mb-4 text-bg-transparent'
                style={{
                  background: item.color || 'black',
                }}
              >
                <span className='text-3xl leading-none'>{item.number}</span>
                <span className='text-sm'>{item.smallUnit}</span>
              </div>
              <div
                className={`text-black font-medium text-bg-transparent`}
                style={{
                  background: item.color || 'black',
                }}
                dangerouslySetInnerHTML={{ __html: item.smallText }}
              ></div>
            </div>
          ))}
        </div> */}
        {/* <div className='mx-auto text-center'>
          <img
            className='object-cover mx-auto max-w-[600px] w-full'
            src={require('../../assets/images/about/' + about.nameImg)}
            alt='map'
          />
        </div> */}
        <div className='w-full pt-6'>
          {paragraph?.paragraphs?.map((item, index) => (
            <div
              key={index}
              className='md:grid grid-cols-2 gap-6 mb-6 last:mb-0'
            >
              <div
                className={`${
                  index % 2 !== 0 ? 'order-1' : 'order-2'
                } rounded overflow-hidden`}
              >
                <img
                  className={
                    'mb-6 md:mb-0 w-full ' +
                    (index % 2 === 0 ? 'rounded-l-full' : 'rounded-r-full')
                  }
                  src={require('../../assets/images/about/' + item.nameImage)}
                  alt=''
                />
              </div>
              <div
                className={`text-black font-medium flex flex-col justify-center  ${
                  index % 2 !== 0 ? 'order-2' : 'order-1'
                } `}
              >
                <div
                  className={`text-black font-medium ${
                    index % 2 !== 0 ? 'order-2' : 'order-1'
                  } `}
                  // className={`${
                  //   index % 2 !== 0 ? 'order-2' : 'order-1'
                  // } first-letter:text-5xl first-letter:font-bold first-letter:mr-2 first-letter:float-left md:mb-0 mb-12`}
                  dangerouslySetInnerHTML={{ __html: item.content }}
                ></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
